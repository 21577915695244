<template>
  <div class="wac-cms-pour-estimator">
    <!-- modalTopData -->
    <wac-text v-if="modalTopData.headline" :content="modalTopData.headline" />

    <wac-text v-if="modalTopData.text" :content="modalTopData.text" />

    <div v-if="modalTopData.link">
      <div
        v-for="link in modalTopData.link"
        :key="link.props.text"
        class="wac-cms-pour-estimator-link"
      >
        <wac-link v-bind="link.props" />
      </div>
    </div>

    <!-- productForm -->
    <wac-form-control
      :label-text="productData.label"
      :errors="productValueError"
    >
      <wac-select
        :error="productValueError.length > 0"
        :options="productSelection"
        :placeholder="productData.placeholder"
        @change="onProductSelect"
        width="100%"
      />
    </wac-form-control>

    <!-- modelVolumeForm -->
    <wac-form-control
      :label-text="modelData.label"
      :errors="modelErrorMessages"
    >
      <div class="wac-form-control-double">
        <wac-input
          :errors="modelValueError"
          :value="modelValue | numberFormat(langCode)"
          @input="onInput('modelValue', $event)"
        />
        <wac-select
          :error="modelUnitError"
          :options="modelSelection"
          :placeholder="modelData.placeholder"
          @change="onModelSelect"
          width="100%"
        />
      </div>
    </wac-form-control>

    <!-- boxVolumeForm -->
    <wac-form-control :label-text="boxData.label" :errors="boxErrorMessages">
      <div class="wac-form-control-double">
        <wac-input
          :errors="boxValueError"
          :value="boxValue | numberFormat(langCode)"
          @input="onInput('boxValue', $event)"
        />
        <wac-select
          :error="boxUnitError"
          :options="boxSelection"
          :placeholder="boxData.placeholder"
          @change="onBoxSelect"
          width="100%"
        />
      </div>
    </wac-form-control>

    <!-- displayResultsForm -->
    <wac-form-control :label-text="resultData.label" :errors="resultUnitError">
      <wac-select
        :error="resultUnitError.length > 0"
        :options="resultSelection"
        :placeholder="resultData.placeholder"
        @change="onResultChange"
        width="100%"
      />
    </wac-form-control>

    <!-- button -->
    <wac-button
      :text="button.label"
      @click="calculateResult"
      variant="primary-solid"
      size="medium"
    />

    <!-- resut -->
    <div class="wac-cms-pour-estimator-result" v-if="calculatedValue">
      {{ result.label }} {{ calculatedValue | numberFormat(langCode) }}
      {{ resultUnit }}
    </div>

    <!-- error -->
    <div class="wac-cms-pour-estimator-result" v-if="errorOnCalculated">
      {{ errorOnCalculated }}
    </div>

    <!-- modalBottomData -->
    <div v-if="modalBottomData.link">
      <div
        v-for="link in modalBottomData.link"
        :key="link.props.text"
        class="wac-cms-pour-estimator-link-bottom"
      >
        <wac-link v-bind="link.props" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  WacFormControl,
  WacSelect,
  WacInput,
  WacButton,
  WacLink,
  WacText,
} from "wacker-pattern-library";
import {
  cm3Convert,
  formularCastingMold,
  isValidInput,
  preselectFirst,
  numberFormat,
} from "./utils";

export default {
  props: {
    modalTopData: {
      type: Object,
    },
    modalBottomData: {
      type: Object,
    },
    calculatorData: {
      type: Object,
      required: true,
    },
  },
  components: {
    WacFormControl,
    WacSelect,
    WacInput,
    WacButton,
    WacLink,
    WacText,
  },
  created() {
    this.langCode = window.location.pathname.split("/").filter((x) => !!x)[0];
    this.productData = this.calculatorData.productForm;
    this.modelData = this.calculatorData.modelVolumeForm;
    this.boxData = this.calculatorData.boxVolumeForm;
    this.resultData = this.calculatorData.displayResultsForm;
    this.button = this.calculatorData.button;
    this.result = this.calculatorData.result;
    this.errorEqual = this.calculatorData.errorEqual;
    this.errorGreaterThan = this.calculatorData.errorGreaterThan;
    this.generalErrorMessage = this.calculatorData.generalErrorMessage;
    preselectFirst(
      this.productData.products,
      "productSelection",
      "productValue",
      this
    );
    preselectFirst(this.modelData.units, "modelSelection", "modelUnit", this);
    preselectFirst(this.boxData.units, "boxSelection", "boxUnit", this);
    preselectFirst(
      this.resultData.units,
      "resultSelection",
      "resultUnit",
      this
    );
  },
  data() {
    return {
      langCode: "",

      validation: false,
      // products
      productData: undefined,
      productSelection: undefined,
      productValue: undefined,
      // model
      modelData: undefined,
      modelUnit: undefined,
      modelValue: undefined,
      modelSelection: undefined,
      // box
      boxData: undefined,
      boxUnit: undefined,
      boxValue: undefined,
      boxSelection: undefined,
      // result
      resultData: undefined,
      resultSelection: undefined,
      resultUnit: undefined,
      calculatedValue: undefined,
      // errors
      errorOnCalculated: undefined,
    };
  },
  computed: {
    productValueError() {
      return this.validation && !isValidInput(this.productValue)
        ? [this.productData.error]
        : [];
    },
    modelValueError() {
      return this.validation && !isValidInput(this.modelValue) ? [""] : [];
    },
    modelUnitError() {
      return this.validation && !isValidInput(this.modelUnit);
    },
    modelErrorMessages() {
      const error = [];
      if (this.modelValueError.length >= 1) {
        error.push(this.modelData.error.value);
      }
      if (this.modelUnitError) {
        error.push(this.modelData.error.unit);
      }
      return error;
    },
    boxValueError() {
      return this.validation && !isValidInput(this.boxValue) ? [""] : [];
    },
    boxUnitError() {
      return this.validation && !isValidInput(this.boxUnit);
    },
    boxErrorMessages() {
      const error = [];
      if (this.boxValueError.length >= 1) {
        error.push(this.boxData.error.value);
      }
      if (this.boxUnitError) {
        error.push(this.boxData.error.unit);
      }
      return error;
    },
    resultUnitError() {
      return this.validation && !isValidInput(this.resultUnit)
        ? [this.resultData.error]
        : [];
    },
  },
  filters: { numberFormat },
  methods: {
    onProductSelect(value) {
      this.productValue = value;
    },
    onInput(name, value) {
      let clearedValue = '';
      const decimal = this.langCode === "de" ? "," : ".";

      // wait for user input after decimal
      if (value.slice(-1) === decimal) {
        return;
      }

      if (this.langCode === "de") {
        clearedValue = value.replace(/\./g, "").replace(/,/g, ".");
      } else {
        clearedValue = value.replace(/,/g, "");
      }

      if (clearedValue === "") {
        this[name] = undefined; // clear input
        this[name] = "";
      } else {
        const parsedValue = parseFloat(clearedValue).toFixed(3);

        this[name] = undefined; // clear input
        this[name] = parsedValue;
      }
      this.resetResult();
    },
    onModelSelect(value) {
      this.resetResult();
      this.modelUnit = value;
    },
    onBoxSelect(value) {
      this.resetResult();
      this.boxUnit = value;
    },
    onResultChange(value) {
      this.resetResult();
      this.resultUnit = value;
    },
    resetResult() {
      this.calculatedValue = undefined;
      this.errorOnCalculated = undefined;
    },
    calculateResult() {
      this.validation = true;
      this.resetResult();

      if (
        this.productValueError.length > 0 ||
        this.modelErrorMessages.length > 0 ||
        this.boxErrorMessages.length > 0 ||
        this.resultUnitError.length > 0
      ) {
        return;
      }

      const keyModel = `${this.modelUnit}:cm3`;
      const modelValue = cm3Convert[keyModel](this.modelValue);
      const keyBox = `${this.boxUnit}:cm3`;
      // we convert user input to cm3, so only cm3:g and cm3:kg is needed
      const boxValue = cm3Convert[keyBox](this.boxValue);

      if (modelValue === boxValue) {
        this.errorOnCalculated = this.errorEqual;
        return;
      } else if (modelValue > boxValue) {
        this.errorOnCalculated = this.errorGreaterThan;
        return;
      } else {
        try {
          const keyResult = `cm3:${this.resultUnit}`;
          this.calculatedValue = formularCastingMold[keyResult](
            this.productValue,
            boxValue,
            modelValue
          ).toString();
        } catch (error) {
          console.error(error);
          this.errorOnCalculated = "Failure";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wac-cms-pour-estimator {
  // TODO: check spacing when modal is ready
  margin: 30px;

  &::v-deep {
    .wac-form-control + .wac-form-control {
      margin-top: 18px;
    }

    .wac-select-wrapper {
      width: 100%;

      .placeholder {
        color: #666666;
      }
    }

    .wac-flyout-wrapper {
      width: 100%;
    }

    .wac-form-control-double {
      display: flex;
      gap: 8px;

      .wac-form-control {
        width: 30%;

        .wac-input,
        input {
          width: 100%;
        }
      }

      .wac-select-wrapper {
        width: 70%;
      }

      .wac-flyout-wrapper {
        width: 100%;
      }
    }

    .wac-button {
      margin: 30px 0 20px 0;
    }

    & p {
      font-size: 16px !important;
      margin-bottom: 20px;
    }

    & a {
      font-size: 15px !important;
    }

    & .wac-selection-options span,
    & .wac-selection-options span p {
      font-size: 14px !important;
    }

    & .wac-form-errors {
      display: flex;
      flex-direction: column;
    }
  }

  .wac-cms-pour-estimator-result {
    background-color: #e6f5f7;
    padding: 20px;
    font-size: 20px;
  }
  .wac-cms-pour-estimator-link {
    margin: 5px 0 20px 0;
  }
  .wac-cms-pour-estimator-link-bottom {
    margin: 20px 0 20px 0;
  }
}
</style>
