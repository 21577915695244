<template>
  <wac-cms-theme
    v-if="showWrapper"
    :id="id"
    :name="wrapper.theme"
    :class="[
      'wac-cms-component',
      wrapper.debug ? 'wac-cms-debug' : '',
      wrapper.highlight ? 'wac-cms-highlight' : '',
      cls
    ]"
  >
    <component :is="type" ref="el" v-bind="props" v-on="eventHandler">
      <wac-cms-component
        v-for="child in children"
        :key="child.id"
        :slot="child.meta.slot"
        v-bind="child"
        @mounted="onMounted"
        @action="onAction"
      />
    </component>
    <div v-if="showWrapper && wrapper.info" class="wac-cms-info">
      {{ wrapper.info }}
    </div>
    <div v-if="showWrapper && wrapper.warning" class="wac-cms-warning">
      {{ wrapper.warning }}
    </div>
    <div v-if="showWrapper && wrapper.error" class="wac-cms-error">
      {{ wrapper.error }}
    </div>
    <code v-if="wrapper.debug"
      ><pre>{{ JSON.stringify($props, null, 2) }}</pre></code
    >
  </wac-cms-theme>
  <component
    :is="type"
    v-else
    :id="id"
    ref="el"
    v-bind="props"
    v-on="eventHandler"
  >
    <wac-cms-component
      v-for="child in children"
      :key="child.id"
      :slot="child.meta.slot"
      v-bind="child"
      @mounted="onMounted"
      @action="onAction"
    />
  </component>
</template>

<script>
import Bundle from "./WacCmsBundle.js";
import WacCmsTheme from "../components/internal/WacCmsTheme/WacCmsTheme.vue";

export default {
  name: "WacCmsComponent",
  components: {
    WacCmsTheme
  },
  mixins: [Bundle],
  props: {
    id: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      require: true
    },
    meta: {
      type: Object,
      default: undefined
    },
    on: {
      type: Object,
      default: undefined
    },
    props: {
      type: Object,
      require: true
    },
    in: {
      type: Object,
      default: undefined
    },
    cls: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      wrapper: {
        debug: this.meta?.debug || false,
        highlight: this.meta?.highlight || false,
        info: this.meta?.info,
        warning: this.meta?.warning,
        error: this.meta?.error,
        theme: this.meta?.theme
      }
    };
  },
  computed: {
    slots() {
      return this.in ? Object.keys(this.in) : [];
    },
    children() {
      const result = [];
      this.slots.forEach(slot => {
        if (this.in[slot]) {
          this.in[slot].forEach(child => {
            result.push({ ...child, ...{ meta: { slot: slot } } });
          });
        }
      });
      return result;
    },
    eventHandler() {
      if (!this.on) return;
      const handler = {};
      Object.entries(this.on).forEach(([key, value]) => {
        handler[key] = (arg1, arg2, arg3, arg4) => {
          const args = [];
          if (arg1) args.push(arg1);
          if (arg2) args.push(arg2);
          if (arg3) args.push(arg3);
          if (arg4) args.push(arg4);
          this.$emit("action", value, this.$refs.el, args);
        };
      });
      return handler;
    },
    showWrapper() {
      return (
        this.wrapper.debug ||
        this.wrapper.highlight ||
        this.wrapper.theme ||
        this.cls
      );
    }
  },
  watch: {
    meta: {
      deep: true,
      handler(val) {
        this.wrapper.debug = val.debug;
        this.wrapper.theme = val.theme;
      }
    }
  },
  mounted() {
    this.onMounted(this.id, this.$refs.el);
  },
  methods: {
    onMounted(id, el) {
      // notify to parent cms component or app
      if (id) this.$emit("mounted", id, el);
    },
    onAction(event, el, args) {
      // forward abstract event to parent cms component or app
      console.log("inner action", event, el, args);
      this.$emit("action", event, el, args);
    }
  }
};
</script>

<style scoped>
.wac-cms-debug {
  border: none;
}
.wac-cms-info,
.wac-cms-warning,
.wac-cms-error,
.wac-cms-debug pre {
  font-family: "Operator Mono", "Fira Code Retina", "Fira Code",
    "FiraCode-Retina", "Andale Mono", "Lucida Console", "Consolas,Monaco",
    monospace;
  -webkit-font-smoothing: antialiased;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.85);
  color: #a8ff60;
  margin: 0;
  font-size: 11px;
  line-height: 15px;
  overflow-x: auto;
}
.wac-cms-info {
  background-color: silver;
}
.wac-cms-warning {
  background-color: yellow;
}
.wac-cms-error {
  background-color: orangered;
}
.wac-cms-highlight {
  background-color: yellowgreen;
}
</style>
