<template>
  <li v-if="!isOneChildGroup" :class="itemClass">
    <a v-if="itemVisible" :href="url" :class="linkClass" @click="onItemClick">
      <span>
        <wac-icon v-if="backVisible" size="medium" variant="angle-left" />
        {{ text }}
      </span>
      <wac-icon v-if="nextVisible" size="medium" variant="angle-right" />
    </a>
    <ul v-show="childrenVisible" :class="childrenClass">
      <li v-show="titleVisible" class="wac-nav-item">
        <a :href="titleUrl" class="wac-nav-title" @click="onTitleClick">
          {{ title }}
        </a>
      </li>
      <slot name="children" />
    </ul>
  </li>
  <div v-else>
    <slot name="children" />
  </div>
</template>

<script>
import { WacIcon } from "wacker-pattern-library";

export default {
  name: "WacCmsNavigationItem",
  components: {
    WacIcon
  },
  props: {
    text: {
      type: String,
      require: true
    },
    title: {
      type: String
    },
    href: {
      type: String,
      default: ""
    },
    id: {
      type: String
    }
  },
  inject: {
    _navigationLevel: {
      default: () => () => 0
    },
    _navigationPath: {
      default: () => () => ""
    },
    _navigationState: {
      default: () => () => {}
    }
  },
  provide() {
    return {
      _navigationLevel: () => this.level,
      _navigationPath: () => this.path
    };
  },
  computed: {
    level() {
      return this._navigationLevel() + (this.isOneChildGroup ? 0 : 1);
    },
    group() {
      /**
       * for mobile: if group has only one item avoid unwanted subgroup behavior
       */
      return !this.hasUrl && this.hasChildren;
    },
    groupWithTitle() {
      return this.title && this.href && this.hasChildren;
    },
    hasUrl() {
      return this.href && !this.groupWithTitle;
    },
    url() {
      return this.hasUrl ? this.href : "#";
    },
    isOneChildGroup() {
      /**
       * this check is needed to ensure the a desktop ui group with
       * only one child doesn't behave like a (sub)group on mobile
       */
      return (
        this.isMobile &&
        this.group &&
        this.hasChildren &&
        this.$slots.children.length === 1
      );
    },
    isMobile() {
      return this._navigationState().layout === "mobile";
    },
    isSelected() {
      return this._navigationState().value === this.path;
    },
    isActive() {
      return (
        this._navigationState().value &&
        this._navigationState().value.substr(0, this.path.length) === this.path
      );
    },
    hasChildren() {
      return this.$slots.children !== undefined;
    },
    itemClass() {
      const result = ["wac-nav-item", this.levelClass];
      if (this.group) result.push("wac-nav-group");
      if (this.isSelected) result.push("wac-nav-selected");
      if (this.isActive) result.push("wac-nav-active");
      return result;
    },
    childrenClass() {
      const result = [this.levelClass];
      if (this.isActive) result.push("wac-nav-active");
      return result;
    },
    linkClass() {
      return ["wac-nav-link", this.levelClass];
    },
    levelClass() {
      return `wac-nav-level-${this.level}`;
    },
    itemVisible() {
      return (
        !this.isMobile ||
        this._navigationState().level === this._navigationLevel() ||
        (this.isActive && this._navigationState().level - this.level === 1) ||
        this.isSelected
      );
    },
    titleUrl() {
      return this.href ? this.href : "#";
    },
    titleVisible() {
      return this.title && (!this.isMobile || this.isSelected);
    },
    childrenVisible() {
      return (
        this.hasChildren &&
        (!this.isMobile ||
          this._navigationState().level === this._navigationLevel() ||
          this.isActive ||
          this.isSelected)
      );
    },
    backVisible() {
      return (
        this.isMobile &&
        this.isActive &&
        !this.isSelected &&
        this._navigationState().level - this.level === 1
      );
    },
    nextVisible() {
      return this.isMobile && !this.isActive && !this.isSelected && this.group;
    },
    itemId() {
      return this.id || this.text || this._uid;
    },
    path() {
      const parent = this._navigationPath() ? this._navigationPath() + "/" : "";
      return parent + this.itemId;
    }
  },
  methods: {
    onClick(e, url, hasUrl) {
      if (url === "#") e.preventDefault();
      if (hasUrl && url === "#")
        this._navigationState().component.goTo(
          this.$props,
          this.path,
          this.level
        );
      else
        this._navigationState().component.select(
          this.$props,
          this.path,
          this.level
        );
    },
    onTitleClick(e) {
      this.onClick(e, this.titleUrl, this.groupWithTitle);
    },
    onItemClick(e) {
      this.onClick(e, this.url, this.hasUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-nav-title,
.wac-nav-group,
.wac-nav-item {
  @include fontSize(16px, 2.25);
  margin: 0;
}

.wac-nav-title,
.wac-nav-link {
  text-decoration: none;
  color: var(--wac-color-grey-800);
}

nav.wac-nav-layout--sitemap {
  .wac-nav-title,
  .wac-nav-item {
    margin-left: var(--spacing-200);
  }
}

nav.wac-nav-layout--desktop {
  .wac-nav-item.wac-nav-level-1 {
    display: inline-block;
    margin-right: var(--spacing-300);

    &:hover .wac-nav-link.wac-nav-level-1,
    &.wac-nav-active .wac-nav-link.wac-nav-level-1 {
      border-bottom-color: rgb(var(--wac-color-grey-700-rgb));
    }

    &.wac-nav-active {
      ul.wac-nav-level-1 {
        display: block;
      }

      .wac-nav-title:hover {
        text-decoration: underline;
      }
    }

    ul.wac-nav-level-1 {
      position: absolute;
      z-index: 3050;
      background-color: var(--wac-color-white);
      box-shadow: 0 5px 12px 0 rgba(var(--wac-color-black-rgb), 0.2);
      padding: 17px 20px 25px;
      display: none;
      min-width: 270px;
    }

    .wac-nav-link.wac-nav-level-1 {
      @include fontSize(18px, 1.33);
      padding: var(--spacing-150) 0 var(--spacing-100);
      display: block;
      border-bottom: solid 8px rgb(var(--wac-color-white-rgb));
      color: var(--wac-color-grey-800);
    }
  }

  .wac-nav-group {
    display: inline-block;
    vertical-align: top;
  }

  .wac-nav-level-1 .wac-nav-group {
    & + .wac-nav-group {
      margin-left: var(--spacing-400);
    }
  }

  .wac-nav-level-2 {
    &:not(.wac-nav-group) > .wac-nav-link:hover {
      text-decoration: underline;
    }

    &.wac-nav-group {
      .wac-nav-link.wac-nav-level-2 {
        @include fontSize(24px, 1.25);
        display: block;
        padding: var(--spacing-100) 0;
        font-weight: 300;

        &:hover {
          cursor: default;
        }
      }

      .wac-nav-link.wac-nav-level-3:hover {
        text-decoration: underline;
      }
    }
  }

  .wac-nav-title {
    font-weight: 500;
  }
}

nav.wac-nav-layout--mobile {
  .wac-nav-selected {
    > .wac-nav-link.wac-nav-level-1,
    > .wac-nav-link.wac-nav-level-2 {
      @include fontSize(26px, 1.15);
      cursor: default;
      font-weight: 300;
    }

    .wac-nav-title {
      display: block;
    }
  }

  .wac-nav-active:not(.wac-nav-selected) > .wac-nav-link.wac-nav-level-1 {
    @include backTo();
    margin-top: 5px;
    border-bottom: 0 none;
  }

  .wac-nav-link {
    display: flex;
    justify-content: space-between;

    &::v-deep .wac-icon-angle-right {
      margin-top: 3px;
    }
  }

  .wac-nav-title,
  .wac-nav-link {
    @include fontSize(18px, 1.5);
    border-bottom: solid 1px rgb(var(--wac-color-grey-250-rgb));
    padding: var(--spacing-150) 0;
  }
}
</style>
