<template>
  <wac-background variant="primary">
    <wac-layout>
      <wac-cms-product-selector v-bind="$props">
        <template #search>
          <slot name="search" />
        </template>
      </wac-cms-product-selector>
    </wac-layout>
  </wac-background>
</template>

<script>
import WacCmsProductSelector from "./WacCmsProductSelector.vue";
import { WacLayout, WacBackground } from "wacker-pattern-library";

export default {
  name: "WacCmsProductSelectorModule",
  components: {
    WacLayout,
    WacBackground,
    WacCmsProductSelector
  },
  props: {
    /** Property headline is used as headline */
    headline: Array
  }
};
</script>
