<template>
  <wac-background class="wac-cms-intro-box" :variant="variant">
    <wac-layout>
      <div v-if="$slots.breadcrumb" class="wac-cms-intro-breadcrumb">
        <!-- @slot Description for breadcrump slot <br>
        <code><pre>
        breadcrumb: [
            {
            "type": "wac-breadcrumb",
            "props": {
              "items": [
                { "href": "#", "text": "Home" },
                { "href": "#", "text": "Intro Box" },
                { "href": "#", "text": "Preview" }
              ]
            }
          }
        ]
        </pre></code>
        -->
        <slot name="breadcrumb" />
      </div>
      <div class="wac-cms-intro-text">
        <wac-text-media
          v-bind="$props"
          :level="1"
          :variant="null"
          :class="{ 'wac-cms-intro-text__headline--no-border': !hasContent }"
        >
          <!-- @slot Description for button slot -->
          <slot slot="button" name="button" />
          <!-- @slot Describes the media slot <br>
          <code><pre>
          media: [
              {
              "type": "wac-cms-image",
              "props": {
                  "url": "assets/looking_through_microscope_480x320.jpg",
                  "width": 480,
                  "height": 320,
                  "title": "Hello World",
                  "alt": "Alternate Text"
                }
              }
          ]
          </pre></code>
          -->
          <slot slot="media" name="media" />
        </wac-text-media>
      </div>
    </wac-layout>
  </wac-background>
</template>

<script>
import { WacBackground, WacLayout, WacTextMedia } from "wacker-pattern-library";
/**
 * Intro box modul with breadcrump and selectable background color variants. This modul use the following components from the wacker-pattern-library. See there for more details<br>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/docs/components-molecules-breadcrumbs--default">WacBreadcrumb</a>
 */
export default {
  name: "WacCmsIntroBox",
  components: {
    WacBackground,
    WacLayout,
    WacTextMedia
  },
  props: {
    headline: Array,
    content: Array,
    /**
     * Image or media subline as content array. Text is only displayed if a media component is included.
     */
    subline: Array,
    alignment: String,
    size: String,
    /**
     * The background color of the intro box as theme variant
     */
    variant: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    hasContent() {
      const contentArr = this.$props.content || [];

      return (
        !!this.$slots.button || !!this.$slots.media || contentArr.length !== 0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-intro-breadcrumb {
  padding-top: 11px;
  padding-bottom: 21px;
}

@media only screen and (max-width: $bp-medium) {
  .wac-cms-intro-breadcrumb {
    display: none;
  }
}

.wac-cms-intro-box ::v-deep {
  .wac-cms-intro-text {
    padding-top: var(--spacing-300);
    padding-bottom: 50px;
  }

  .wac-text p {
    @include fontSize(20px, 1.5);
    padding-bottom: var(--spacing-300);
  }
}

// Remove separator line from h1
.wac-cms-intro-text__headline--no-border::v-deep {
  .wac-text h1 {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }
}
</style>
