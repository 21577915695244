<template>
  <div class="consent-notification">
    <div
      class="
        consent-notification__aspect-container
        consent-notification__aspect-container--16-9
      "
    >
      <div class="consent-notification__container">
        <p class="consent-notification__text">
          For displaying additional content and functions, we need your consent
          to set cookies. Please accept functional cookies via your cookie
          settings.
        </p>
        <button class="btn btn-default ot-sdk-show-settings">
          Cookie settings
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WacCmsConsentNotification",
};
</script>

<style lang="scss" scoped>
.consent-notification {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    -45deg,
    #d0d0d0 12.5%,
    #d0d0d0 12.5%,
    #ccc 37.5%,
    #d0d0d0 37.5%,
    #d0d0d0 62.5%,
    #ccc 62.5%,
    #ccc 87.5%,
    #d0d0d0 87.5%
  );
  background-size: 80px 80px;
  background-position: 50px 50px;
  position: relative;

  &__aspect-container {
    width: 100%;
    padding-top: 100%;
    position: relative;

    &--5-3 {
      padding-top: 60% !important;
    }

    &--10-3 {
      padding-top: 30% !important;
    }

    &--16-9 {
      padding-top: 56.25% !important;
    }
  }

  &__container {
    margin: 0 auto !important;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__text {
    margin: 0 20px 20px !important;
    padding-top: 0 !important;
    max-height: 80px;
    overflow-y: auto;
    overflow-x: visible;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center;
    color: #333 !important;
  }

  & .btn.btn-default {
    margin: 0;
    background-color: #555;
    color: #fff;
    border: none;
    font-size: 15px;
    min-height: initial;
    line-height: 20px;
    padding: 7px 25px;

    &:hover {
      background-color: #777;
      color: #fff;
    }
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
    &.hide-on-mobile {
      display: none;
    }
  }

  @media (max-width: 480px) {
    &__text {
      font-size: 13px !important;
      line-height: 16px !important;
    }
  }
}
</style>
