<template>
  <nav :class="['wac-nav', `wac-nav-layout--${layout}`]">
    <ul>
      <li v-show="rootVisible">
        <a href="#" class="wac-nav-link__back-to" @click.prevent="goToTop"
          ><wac-icon variant="angle-left" size="medium" />{{ root }}</a
        >
      </li>
      <slot name="children" />
    </ul>
  </nav>
</template>

<script>
import { WacIcon } from "wacker-pattern-library";

export const LAYOUTS = ["sitemap", "desktop", "mobile"];

export default {
  name: "WacCmsNavigation",
  components: {
    WacIcon
  },
  provide() {
    return {
      _navigationState: () => this.state
    };
  },
  props: {
    root: {
      type: String
    },
    value: {
      type: String
    },
    layout: {
      type: String,
      default: "auto"
    }
  },
  data() {
    return {
      state: {
        value: this.value,
        level: this.getLevel(this.value),
        component: this,
        layout: this.layout
      }
    };
  },
  computed: {
    hasChildren() {
      return this.$slots.children !== undefined;
    },
    isMobile() {
      return this.layout === "mobile";
    },
    rootVisible() {
      return this.isMobile && this.state.level === 1;
    }
  },
  watch: {
    layout(newValue) {
      this.state.layout = newValue;
    },
    value(newValue) {
      this.state.value = newValue;
      this.state.level = this.getLevel(newValue);
    }
  },
  methods: {
    select(props, path, level) {
      this.state.value = path;
      this.state.level = level;
      this.$emit("select", { props, path, level });
    },
    goTo(props, path, level) {
      this.state.value = null;
      this.state.level = 0;
      this.$emit("goTo", { props, path, level });
    },
    goToTop() {
      this.select(null, null, 0);
    },
    getLevel(path) {
      return path ? path.split("/").length : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-nav {
  ::v-deep {
    li {
      list-style: none;
      padding: 0;
    }
  }

  &-link__back-to {
    @include backTo();
    display: block;
    margin-top: var(--spacing-200);
    text-decoration: none;
  }
}
</style>
