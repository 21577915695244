<template>
  <div class="wac-cms-modal">
    <div class="wac-cms-modal__backdrop">
      <div class="wac-cms-modal__close_btn" @click="onClose">
        <wac-icon variant="cross" color="white" />
      </div>

      <div class="wac-cms-modal__wrapper">
        <wac-layout>
          <div class="wac-cms-modal__content">
            <!-- headline -->
            <wac-typography
              v-if="headline"
              class="wac-cms-modal__headline"
              element="h3"
              variant="h3-5"
              color="white"
              >{{ headline }}</wac-typography
            >
            <!-- @slot Use this slot for modal content -->
            <div class="wac-cms-modal__inner">
              <slot name="modalContent" />
            </div>

            <!-- subline -->
            <wac-typography
              v-if="subline"
              class="wac-cms-modal__subline"
              element="p"
              variant="body-5"
              color="white"
              >{{ subline }}</wac-typography
            >
          </div>
        </wac-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { WacIcon, WacTypography, WacLayout } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-icon--default">WacIcon</a>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography--default">WacTypography</a>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-layout-page--default">WacLayout</a>
 */

export default {
  name: "WacCmsModal",
  components: {
    WacIcon,
    WacTypography,
    WacLayout
  },
  props: {
    /** Property headline is used as optional headline above the content */
    headline: String,
    /** Property headline is used as optional subline below the content */
    subline: String
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-modal {
  $self: &;
  position: absolute;
  z-index: var(--z-index-2250);

  &__backdrop {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(var(--wac-color-grey-800-rgb), 0.8);
    display: table;
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__content {
    width: auto;
    max-width: 750px;
    margin: 0 auto;
  }

  &__inner {
    max-height: 70vh;
    overflow: auto;
  }

  &__headline {
    margin-bottom: var(--spacing-150);
  }

  &__subline {
    margin-top: var(--spacing-100);
  }

  &__close_btn {
    cursor: pointer;
    position: absolute;
    right: var(--spacing-150);
    top: var(--spacing-150);

    .wac-icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }

  @media only screen and (min-width: $bp-medium) {
    #{ $self }__close_btn {
      right: 45px;
      top: 45px;
      padding: var(--spacing-100);

      .wac-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
