<template>
  <div :class="['wac-cms-text-media-list', `wac-background-${background}`]">
    <wac-layout>
      <!-- @slot Describes the item slot <br>
			<code><pre>
			items: [
        {
          "type": "wac-cms-text-media-item",
          "props": {
              "headline": [],
              "content": [],
              "alignment": "left",
              "size": "small"
              "level": 2
          }
        }
			]
			</pre></code>
			-->
      <slot name="items" />
    </wac-layout>
  </div>
</template>
<script>
import { WacLayout } from "wacker-pattern-library";

export default {
  name: "WacCmsTextMediaList",
  components: {
    WacLayout
  },
  props: {
    background: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-text-media-list {
  padding: var(--spacing-600) 0;
}
@media only screen and (max-width: $bp-small) {
  .wac-cms-text-media-list {
    padding: var(--spacing-400) 0;
  }
}
.wac-cms-text-media-list::v-deep {
  .wac-text-media {
    margin-top: var(--spacing-600);
  }
  @media only screen and (max-width: $bp-small) {
    .wac-text-media {
      margin-top: var(--spacing-400);
    }
  }
  .wac-text-media:first-child {
    margin-top: 0;
  }
}
</style>
