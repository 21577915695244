<template>
  <wac-layout>
    <wac-spacing>
      <wac-cms-image-text-teaser :headline="headline">
        <template #items>
          <slot name="items" />
        </template>
      </wac-cms-image-text-teaser>
    </wac-spacing>
  </wac-layout>
</template>
<script>
import WacCmsImageTextTeaser from "../WacCmsImageTextTeaser/WacCmsImageTextTeaser.vue";
import { WacLayout } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

export default {
  name: "WacCmsImageTextTeaserModule",
  components: {
    WacLayout,
    WacSpacing,
    WacCmsImageTextTeaser
  },
  props: {
    /** Property headline is used as headline for the image text teaser module (optional) */
    headline: Array
  }
};
</script>
