<template>
  <div class="wac-spacing">
    <slot />
  </div>
</template>
<script>
export default {
  name: "WacSpacing"
};
</script>
<style lang="scss" scoped>
.wac-spacing {
  padding: var(--spacing-600) 0;
}
.wac-spacing-reduce-top .wac-spacing {
  padding-top: 0;
}
@media only screen and (max-width: $bp-small) {
  .wac-spacing {
    padding: var(--spacing-400) 0;
  }
}
</style>
