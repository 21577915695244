<template>
  <div class="wac-cms-product-teaser-item">
    <a class="wac-cms-product-teaser-item__link" :href="href" :target="target">
      <wac-text
        class="wac-cms-product-teaser-item__headline"
        :content="headlineContent"
      />
      <wac-text class="wac-cms-product-teaser-item__text" :content="text" />
    </a>
  </div>
</template>

<script>
import { WacText } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 */

export default {
  name: "WacCmsProductTeaserItem",
  components: { WacText },
  props: {
    /** Property headline is used as headline */
    headline: Array,
    /** Property text is used as content text */
    text: Array,
    /** Property href is used as href link */
    href: String,
    /** Property target is used as link target */
    target: String
  },
  computed: {
    headlineContent() {
      return [{ type: "h3", content: this.headline }];
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-product-teaser-item {
  $self: &;

  display: flex;
  min-height: 142px;
  background-color: var(--wac-bgcolor-primary);

  a {
    text-decoration: none;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-150) var(--spacing-200);
    width: 100%;
  }

  &__headline,
  &__text {
    color: var(--wac-forecolor-primary-bg);
  }

  &__headline {
    margin-bottom: var(--spacing-200);
  }

  &::v-deep {
    #{ $self }__headline > * {
      @include fontSize(30px, 1.2);
      @include textStyle(0, 300);
    }

    #{ $self }__text > * {
      @include fontSize(15px, 1.33);
      @include textStyle(0, 300);
    }
  }

  &:hover {
    background-color: rgba(var(--wac-bgcolor-primary-rgb), 0.8);
  }
}
</style>
