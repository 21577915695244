<template>
  <div class="wac-cms-skin-mold-estimator">
    <!-- modalTopData -->
    <wac-text v-if="modalTopData.headline" :content="modalTopData.headline" />

    <wac-text v-if="modalTopData.text" :content="modalTopData.text" />

    <div v-if="modalTopData.link">
      <div
        v-for="link in modalTopData.link"
        :key="link.props.text"
        class="wac-cms-skin-mold-estimator-link"
      >
        <wac-link v-bind="link.props" />
      </div>
    </div>

    <!-- productForm -->
    <wac-form-control
      :label-text="productData.label"
      :errors="productValueError"
    >
      <wac-select
        :error="productValueError.length > 0"
        :options="productSelection"
        :placeholder="productData.placeholder"
        width="100%"
        @change="onProductSelect"
      />
    </wac-form-control>

    <!-- surfaceOfTheModelForm -->
    <wac-form-control
      :label-text="surfaceData.label"
      :errors="surfaceErrorMessages"
    >
      <div class="wac-form-control-double">
        <wac-input
          :errors="surfaceValueError"
          :value="surfaceValue | numberFormat(langCode)"
          @input="onInput('surfaceValue', $event)"
        />
        <wac-select
          :error="surfaceUnitError"
          :options="surfaceSelection"
          :placeholder="surfaceData.placeholder"
          width="100%"
          @change="onSurfaceSelect"
        />
      </div>
    </wac-form-control>

    <!-- layerThicknessLabelForm -->
    <wac-form-control
      :label-text="layerData.label"
      :errors="layerErrorMessages"
    >
      <div class="wac-form-control-double">
        <wac-input
          :errors="layerValueError"
          :value="layerThicknessValue | numberFormat(langCode)"
          @input="onInput('layerThicknessValue', $event)"
        />
        <wac-select
          :error="layerUnitError"
          :options="layerThicknessSelection"
          :placeholder="layerData.placeholder"
          width="100%"
          @change="onLayerThicknessSelect"
        />
      </div>
    </wac-form-control>

    <!-- displayResultsForm -->
    <wac-form-control :label-text="resultData.label" :errors="resultUnitError">
      <wac-select
        :error="resultUnitError.length > 0"
        :options="resultSelection"
        :placeholder="resultData.placeholder"
        width="100%"
        @change="onResultSelect"
      />
    </wac-form-control>

    <!-- button -->
    <wac-button
      :text="button.label"
      variant="primary-solid"
      size="medium"
      @click="calculateResult"
    />

    <!-- resut -->
    <div v-if="calculatedValue" class="wac-cms-skin-mold-estimator-result">
      {{ result.label }} {{ calculatedValue | numberFormat(langCode) }}
      {{ resultUnit }}
    </div>

    <!-- error -->
    <div v-if="errorOnCalculated" class="wac-cms-skin-mold-estimator-result">
      {{ errorOnCalculated }}
    </div>

    <!-- modalBottomData -->
    <div v-if="modalBottomData.link">
      <div
        v-for="link in modalBottomData.link"
        :key="link.props.text"
        class="wac-cms-skin-mold-estimator-link-bottom"
      >
        <wac-link v-bind="link.props" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  WacFormControl,
  WacSelect,
  WacInput,
  WacButton,
  WacLink,
  WacText,
} from "wacker-pattern-library";
import {
  formularSkinMold,
  isValidInput,
  preselectFirst,
  numberFormat,
} from "./utils";

export default {
  components: {
    WacFormControl,
    WacSelect,
    WacInput,
    WacButton,
    WacLink,
    WacText,
  },
  props: {
    modalTopData: {
      type: Object,
    },
    modalBottomData: {
      type: Object,
    },
    calculatorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      langCode: "",

      // validation
      validation: false,
      // product
      productData: undefined,
      productValue: undefined,
      productSelection: undefined,
      // surface
      surfaceData: undefined,
      surfaceUnit: undefined,
      surfaceValue: undefined,
      surfaceSelection: undefined,
      // layerThickness
      layerData: undefined,
      layerThicknessUnit: undefined,
      layerThicknessValue: undefined,
      layerThicknessSelection: undefined,
      // result
      resultData: undefined,
      resultSelection: undefined,
      resultUnit: undefined,
      // errors
      errorOnCalculated: undefined,
      // calculate
      calculatedValue: undefined,
    };
  },
  computed: {
    productValueError() {
      return this.validation && !isValidInput(this.productValue)
        ? [this.productData.error]
        : [];
    },
    surfaceValueError() {
      return this.validation && !isValidInput(this.surfaceValue) ? [""] : [];
    },
    surfaceUnitError() {
      return this.validation && !isValidInput(this.surfaceUnit);
    },
    surfaceErrorMessages() {
      const error = [];
      if (this.surfaceValueError.length > 0) {
        error.push(this.surfaceData.error.value);
      }
      if (this.surfaceUnitError) {
        error.push(this.surfaceData.error.unit);
      }
      return error;
    },
    layerValueError() {
      return this.validation && !isValidInput(this.layerThicknessValue)
        ? [""]
        : [];
    },
    layerUnitError() {
      return this.validation && !isValidInput(this.layerThicknessUnit);
    },
    layerErrorMessages() {
      const error = [];
      if (this.layerValueError.length > 0) {
        error.push(this.layerData.error.value);
      }
      if (this.layerUnitError) {
        error.push(this.layerData.error.unit);
      }
      return error;
    },
    resultUnitError() {
      return this.validation && !isValidInput(this.resultUnit)
        ? [this.resultData.error]
        : [];
    },
  },
  created() {
    this.langCode = window.location.pathname.split("/").filter((x) => !!x)[0];
    this.productData = this.calculatorData.productForm;
    this.surfaceData = this.calculatorData.surfaceOfTheModelForm;
    this.layerData = this.calculatorData.layerThicknessLabelForm;
    this.resultData = this.calculatorData.displayResultsForm;
    this.button = this.calculatorData.button;
    this.result = this.calculatorData.result;
    this.generalErrorMessage = this.calculatorData.generalErrorMessage;
    preselectFirst(
      this.productData.products,
      "productSelection",
      "productValue",
      this
    );
    preselectFirst(
      this.surfaceData.units,
      "surfaceSelection",
      "surfaceUnit",
      this
    );
    preselectFirst(
      this.layerData.units,
      "layerThicknessSelection",
      "layerThicknessUnit",
      this
    );
    preselectFirst(
      this.resultData.units,
      "resultSelection",
      "resultUnit",
      this
    );
  },
  filters: { numberFormat },
  methods: {
    onInput(name, value) {
      let clearedValue = '';
      const decimal = this.langCode === "de" ? "," : ".";

      // wait for user input after decimal
      if (value.slice(-1) === decimal) {
        return;
      }

      if (this.langCode === "de") {
        clearedValue = value.replace(/\./g, "").replace(/,/g, ".");
      } else {
        clearedValue = value.replace(/,/g, "");
      }

      if (clearedValue === "") {
        this[name] = undefined; // clear input
        this[name] = "";
      } else {
        const parsedValue = parseFloat(clearedValue).toFixed(3);

        this[name] = undefined; // clear input
        this[name] = parsedValue;
      }
      this.resetResult();
    },
    onProductSelect(value) {
      this.resetResult();
      this.productValue = value;
    },
    onSurfaceSelect(value) {
      this.resetResult();
      this.surfaceUnit = value;
    },
    onLayerThicknessSelect(value) {
      this.resetResult();
      this.layerThicknessUnit = value;
    },
    onResultSelect(value) {
      this.resetResult();
      this.resultUnit = value;
    },
    resetResult() {
      this.calculatedValue = undefined;
      this.errorOnCalculated = undefined;
    },
    calculateResult() {
      this.validation = true;
      this.calculatedValue = undefined;
      this.errorOnCalculated = undefined;

      if (
        this.productValueError.length > 0 ||
        this.surfaceErrorMessages.length > 0 ||
        this.layerErrorMessages.length > 0 ||
        this.resultUnitError.length > 0
      ) {
        return;
      }

      try {
        const key = `${this.surfaceUnit}:mm:${this.resultUnit}`;

        this.calculatedValue = formularSkinMold[key](
          this.productValue,
          this.layerThicknessValue,
          this.surfaceValue
        ).toString();
      } catch (error) {
        console.error(error);
        this.errorOnCalculated = "Failure";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wac-cms-skin-mold-estimator {
  // TODO: check spacing when modal is ready
  margin: 30px;

  &::v-deep {
    .wac-form-control + .wac-form-control {
      margin-top: 18px;
    }

    .wac-select-wrapper {
      width: 100%;

      .placeholder {
        color: #666666;
      }
    }

    .wac-flyout-wrapper {
      width: 100%;
    }

    .wac-form-control-double {
      display: flex;
      gap: 8px;

      .wac-form-control {
        width: 30%;

        .wac-input,
        input {
          width: 100%;
        }
      }

      .wac-select-wrapper {
        width: 70%;
      }

      .wac-flyout-wrapper {
        width: 100%;
      }
    }

    .wac-button {
      margin: 30px 0 20px 0;
    }

    & p {
      font-size: 16px !important;
      margin-bottom: 20px;
    }

    & a {
      font-size: 15px !important;
    }

    & .wac-selection-options span,
    & .wac-selection-options span p {
      font-size: 14px !important;
    }

    & .wac-form-errors {
      display: flex;
      flex-direction: column;
    }
  }

  .wac-cms-skin-mold-estimator-result {
    background-color: #e6f5f7;
    padding: 20px;
    font-size: 20px;
  }

  .wac-cms-skin-mold-estimator-link {
    margin: 5px 0 20px 0;
  }
  .wac-cms-skin-mold-estimator-link-bottom {
    margin: 20px 0;
  }
}
</style>
