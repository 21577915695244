<template>
  <div class="wac-cms-image-zoom">
    <div>
      <slot name="preview" />
      <div class="wac-cms-image-zoom-trigger">
        <wac-icon-button icon="plus" variant="grey" @click="showModal = true" />
      </div>
    </div>
    <wac-cms-modal
      v-if="showModal"
      :headline="headline"
      :subline="description"
      @close="showModal = false"
    >
      <template v-slot:modalContent>
        <slot name="zoom" />
      </template>
    </wac-cms-modal>
  </div>
</template>
<script>
import { WacIconButton } from "wacker-pattern-library";
import WacCmsModal from "../WacCmsModal/WacCmsModal.vue";

export default {
  name: "WacCmsImageWithZoom",
  components: {
    WacIconButton,
    WacCmsModal
  },
  props: {
    headline: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showModal: false
    };
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-image-zoom-trigger {
  position: relative;

  &::v-deep {
    .wac-icon-button {
      position: absolute;
      width: 37px;
      height: 37px;
      bottom: var(--spacing-100);
      right: var(--spacing-100);

      .wac-icon {
        height: 17px;
        width: 17px;
      }
    }
  }
}
</style>
