<template>
  <img
    class="wac-cms-image"
    :src="url"
    :alt="title"
    v-bind="$props"
    :url="null"
    @click="onClick"
  />
</template>
<script>
/**
 * Image component description
 */
export default {
  name: "WacCmsImage",
  props: {
    /**
     * An absolute or relative url to the source of the image to be displayed
     */
    url: String,
    /**
     * The real width of the referenced image
     */
    width: Number,
    /**
     * The height of the referenced image in pixels
     */
    height: Number,
    /**
     * Image tooltip and alt tag information combined in one title property.
     * Alt tag can still be passed as a separate prop and thus deactivates the fallback
     */
    title: String
  },
  methods: {
    onClick(ev) {
      /**
       * Passthrough click event
       * @type {Event}
       */
      this.$emit("click", ev);
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-image {
  width: 100%;
  height: auto;
}
</style>
