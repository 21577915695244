<template>
  <wac-layout>
    <wac-spacing>
      <div id="ot-sdk-cookie-policy"></div>
    </wac-spacing>
  </wac-layout>
</template>

<script>
import { WacLayout } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

export default {
  name: "WacCmsCookiePolicyListModule",
  components: {
    WacLayout,
    WacSpacing,
  },
};
</script>
<style lang="scss" scoped>
/* no styles at this moment */
</style>
