<template>
  <div :class="['wac-cms-stage-modul', 'wac-cms-variant-' + variant]">
    <div class="wac-cms-stage-background" :style="backgroundCss">
      <wac-layout>
        <wac-text-media :headline="headline" :content="content" :level="1">
          <slot slot="button" name="button" />
        </wac-text-media>
      </wac-layout>
    </div>
  </div>
</template>

<script>
import { WacLayout, WacTextMedia } from "wacker-pattern-library";

/**
 * White and Black variants
 */
export const VARIANTS = ["white", "dark"];

export default {
  name: "WacCmsStageModul",
  components: {
    WacTextMedia,
    WacLayout
  },
  props: {
    /** Variant forecolor. white or dark */
    variant: {
      type: String,
      default: "white",
      validator: value => VARIANTS.includes(value)
    },
    background: {
      type: String,
      default: ""
    },
    headline: {
      type: Array,
      default: undefined
    },
    content: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    backgroundCss() {
      return `background-image: url(${this.background});`;
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-stage-modul::v-deep {
  .wac-text,
  .wac-text-media-button {
    text-align: center;
  }
  .wac-text-content {
    margin-bottom: 30px;
  }
}
.wac-cms-stage-background {
  min-height: 510px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: var(--spacing-600);
}
/** white and dark variant */
.wac-cms-variant-white::v-deep,
.wac-cms-variant-dark::v-deep {
  .wac-text-media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .wac-text {
    @include fontSize(16px, 1.688);
  }
  .wac-button {
    height: 44px;
    border-radius: 2px;
    border-style: solid;
    margin: 0 0 var(--spacing-600) 0;
  }
  .wac-text h1::after {
    left: 50%;
    margin-top: 30px;
    margin-left: -40px;
  }
  @media only screen and (min-width: $bp-large) {
    .wac-text-content {
      max-width: 850px;
    }
  }
}
/** white variant */
.wac-cms-variant-white::v-deep {
  .wac-text {
    color: var(--wac-color-white);
  }
  .wac-text h1::after {
    background-color: var(--wac-color-white);
  }
  .wac-button {
    border-color: var(--wac-color-white);
    color: var(--wac-color-white);

    &:hover {
      background-color: var(--wac-bgcolor-white);
      color: var(--wac-forecolor-white-bg);
    }
  }
}
/** dark variant */
.wac-cms-variant-dark::v-deep {
  .wac-text {
    color: var(--wac-color-grey-800);
  }
  .wac-text h1::after {
    background-color: var(--wac-color-grey-800);
  }
  .wac-button {
    border-color: var(--wac-color-grey-700);
    color: var(--wac-color-grey-800);

    &:hover {
      background-color: var(--wac-color-grey-700);
      color: var(--wac-forecolor-dark-bg);
    }
  }
}
</style>
