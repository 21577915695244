<template>
  <wac-layout>
    <wac-spacing>
      <wac-cms-form-include
        :source="source"
        :presets="presets"
      ></wac-cms-form-include>
    </wac-spacing>
  </wac-layout>
</template>

<script>
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";
import { WacLayout } from "wacker-pattern-library";
import WacCmsFormInclude from "../WacCmsFormInclude/WacCmsFormInclude.vue";

export default {
  name: "WacCmsFormModule",
  components: {
    WacSpacing,
    WacLayout,
    WacCmsFormInclude,
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    presets: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
