<template>
  <wac-icon-button
    v-show="scrollY > minScrollHeight"
    ref="button"
    class="wac-cms-back-to-top-button"
    variant="grey"
    icon="angle-up"
    @click="toTop"
  />
</template>

<script>
import { WacIconButton } from "wacker-pattern-library";
import { debounce } from "debounce";

export default {
  name: "WacCmsBackToTopButton",
  components: {
    WacIconButton
  },
  props: {
    minScrollHeight: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      scrollY: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: debounce(function() {
      this.scrollY = window.scrollY;
    }, 10),

    toTop: function() {
      this.$refs.button.$el.blur();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-icon-button::v-deep {
  height: 50px;
  width: 50px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  box-shadow: 1px 2px 6px 0 var(--wac-color-grey-700);
  background: var(--wac-color-white);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover .wac-icon {
    fill: var(--wac-back-to-top-icon-hover);
  }
  &:focus {
    box-shadow: 1px 2px 15px 0 var(--wac-color-black);
  }
  .wac-icon {
    width: 38px;
    height: 38px;
    margin-right: 0;
    fill: var(--wac-back-to-top-icon-color);
  }
  @media only screen and (min-width: $bp-medium) {
    height: 55px;
    width: 55px;
    .wac-icon {
      width: 39px;
      height: 39px;
    }
  }
}
.wac-icon-button--grey:hover {
  background-color: var(--wac-color-white);
}
</style>
