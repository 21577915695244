import Vue from "vue";
import App from "./WacCmsApp.vue";

const WacCmsAppDefaults = {
  // instances: [], // debug only
  components: {},
  handler: {},
  jump: id => {
    window.setTimeout(() => {
      const top = document.getElementById(id).offsetTop;
      this.scrollTo(0, top);
    }, 100);
  },
  scrollTo: y => {
    window.scrollTo(0, y);
  }
};

window.WacCmsApp = { ...WacCmsAppDefaults, ...window.WacCmsApp };

window.WacCmsData.forEach((el, idx) => {
  new Vue({
    render: h =>
      h(App, {
        props: {
          sections: el.data,
          icons: idx === 0,
          theme: el.theme || "green"
        },
        on: {
          mounted: components => {
            components.forEach(c => {
              if (window.WacCmsApp.components[c.id])
                console.warn(
                  `Main > Error dublicated id ${c.id}. Component not public available`
                );
              else {
                window.WacCmsApp.components[c.id] = c.component;
                if (
                  window.location.hash &&
                  window.location.hash.substr(1) == c.id
                )
                  window.WacCmsApp.jump(c.id);
              }
            });
          },
          action: (event, component, args) => {
            if (window.WacCmsApp.handler[event])
              window.WacCmsApp.handler[event](component, args);
            else {
              const msg = `Missing page event handler for action ${event}.`;
              console.warn(msg, component, args);
            }
          }
        }
      })
  }).$mount("#" + el.id);
});
