<template>
  <a :href="href" :target="target" class="wac-cms-button">
    <wac-button :text="text" :icon="icon" :variant="variant" @click="onClick" />
  </a>
</template>
<script>
import { WacButton } from "wacker-pattern-library";
/**
 * Link button component with href attribute for referencing other pages.
 * Wrapper component for the button component of the leading pattern library
 * with reduced variants, without click handler and reduced height.
 */
export default {
  name: "WacCmsButton",
  components: { WacButton },
  props: {
    /**
     * The href attribute specifies the URL of the page the link goes to.
     */
    href: {
      type: String,
      default: "#"
    },
    /**
     * The button text to be displayed
     */
    text: String,
    /**
     * The target attribute specifies where to open the linked document.
     */
    target: String,
    /**
     * An optional icon for the button.
     * Example values: <i>check</i> or <i>envelope</i>. For a list of all available icons with codes,
     * see <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-iconography--default">wacker-pattern-library-iconography</a>
     *
     */
    icon: String,
    /**
     * Styling variants for the button. Possible values are <i>primary-solid</i> and <i>secondary-outline</i>.
     * The default variant does not have to be specified explicitly.
     */
    variant: {
      type: String,
      default: "secondary-outline"
    }
  },
  methods: {
    onClick(e) {
      if (this.href === "#") e.preventDefault();
      this.$emit("click", e);
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-button:focus {
  outline: none;
  border: 0;
}
.wac-cms-button {
  ::v-deep {
    .wac-button {
      line-height: 34px;
      height: 34px;

      .wac-text-media & {
        margin-right: 0;
      }
    }
    .wac-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  .wac-text-media & {
    margin-right: 15px;
  }
}
</style>
