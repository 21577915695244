<template>
  <wac-app class="wac-cms-app">
    <wac-cms-theme :name="theme">
      <wac-cms-component
        v-for="item in structure"
        :key="item.id"
        v-bind="item"
        :class="getMetaClasses(item)"
        @mounted="onMounted"
        @action="onAction"
      />
    </wac-cms-theme>
    <wac-icon-sprite v-if="icons" />
  </wac-app>
</template>

<script>
import "../node_modules/wacker-pattern-library/index.css";
import { WacApp, WacIconSprite } from "wacker-pattern-library";
import WacCmsComponent from "./core/WacCmsComponent.vue";
import WacCmsTheme from "./components/internal/WacCmsTheme/WacCmsTheme.vue";
import { version } from "../package";

const last = {
  variant: undefined
};

export default {
  name: "WacCmsApp",
  components: {
    WacApp,
    WacCmsComponent,
    WacIconSprite,
    WacCmsTheme
  },
  props: {
    sections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    icons: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: "petrol"
    }
  },
  data() {
    return {
      components: [],
      structure: this.sections // debug only
    };
  },
  mounted() {
    if (this.components.length > 0) this.$emit("mounted", this.components);

    // app version in console for better reporting
    // only reporting by first app instance
    if (this.icons) console.info(`sattelite app version: ${version}`);
  },
  methods: {
    onMounted(id, comp) {
      this.components.push({ id: id, component: comp });
    },
    onAction(event, comp, args) {
      this.$emit("action", event, comp, args);
    },
    getTheme(item) {
      return item.meta?.theme || this.theme;
    },
    getVariant(item) {
      switch (item.type) {
        case "wac-cms-tag-filter-module":
          return "light";
        case "wac-cms-stage-modul":
          return "stage";
        case "wac-cms-header-module":
          return "header";
        case "wac-cms-product-selector-module":
          return "primary";
        case "wac-cms-highlight-box-module":
          return "white";
        default:
          return item.props?.variant || "white";
      }
    },
    getFullVariant(item) {
      return `${this.getTheme(item)}-${this.getVariant(item)}`;
    },
    getMetaClasses(item) {
      const variant = this.getFullVariant(item);
      const result = [];

      if (variant === last.variant || item.meta?.topSpacing === false)
        result.push("wac-spacing-reduce-top");

      last.variant = variant;
      return result;
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-app::v-deep {
  .wac-theme--drawin {
    --wac-color-primary: #0095aa;
    --wac-forecolor-primary-bg: var(--wac-color-white);
    --wac-color-secondary: #0095aa;
    --wac-forecolor-secondary-bg: var(--wac-color-white);
  }
}
</style>
<style lang="scss">
@use './assets/styles/reset';
@use './assets/styles/variables';
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
p strong,
strong {
  font-weight: 500;
}
</style>
