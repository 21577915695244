<template>
  <wac-tag-filter :headline="headline">
    <a v-for="(tag, idx) in tags" :key="idx" :href="tag.url">
      <wac-tag
        :label="tag.label"
        :selected="tag.selected"
        @click="onClick(...arguments, idx)"
      />
    </a>
  </wac-tag-filter>
</template>
<script>
import { WacTagFilter, WacTag } from "wacker-pattern-library";

export default {
  name: "WacCmsTagFilter",
  components: {
    WacTagFilter,
    WacTag
  },
  props: {
    headline: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      selectedValue: this.value
    };
  },
  computed: {
    tags() {
      return this.items.map(item => {
        return {
          ...item,
          ...{
            selected: item.value === this.selectedValue,
            value: item.value
          }
        };
      });
    }
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    }
  },
  methods: {
    onClick(ev, state, idx) {
      this.selectedValue = state ? this.items[idx].value : "";
      if (!state) {
        /** Clear event */
        this.$emit("clear");
        ev.preventDefault();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
