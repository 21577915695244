<template>
  <wac-background variant="light">
    <div class="wac-cms-tag-filter-module">
      <wac-layout>
        <wac-text :content="headlineContent" />
        <wac-cms-tag-filter v-bind="props" @clear="onClear" />
      </wac-layout>
    </div>
  </wac-background>
</template>
<script>
import WacCmsTagFilter from "../WacCmsTagFilter/WacCmsTagFilter.vue";
import { WacBackground, WacLayout, WacText } from "wacker-pattern-library";

export default {
  name: "WacCmsTagFilterModule",
  components: {
    WacCmsTagFilter,
    WacBackground,
    WacLayout,
    WacText
  },
  props: {
    headline: {
      type: Array,
      default: undefined
    },
    value: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: "#"
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    props() {
      return { value: this.value, items: this.items };
    },
    headlineContent() {
      return [{ type: "h3", content: this.headline }];
    }
  },
  methods: {
    onClear() {
      this.redirect(this.url);
    },
    redirect(url) {
      if (url !== "#") location.href = url;
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-tag-filter-module {
  padding: 40px 0 50px 0;
}
</style>
