<template>
  <div :class="[`wac-cms-highlightbox ${hasTwoItemsClass}`]">
    <wac-background :variant="variant">
      <slot name="left" />
    </wac-background>

    <wac-background v-if="hasRightItem" variant="light">
      <slot name="right" />
    </wac-background>
  </div>
</template>

<script>
import { WacBackground } from "wacker-pattern-library";
import { HIGHLIGHTVARIANTS } from "./highlightvariants";
/**
 * Highligth box component description
 */
export default {
  name: "WacCmsHighlightBox",
  components: { WacBackground },
  props: {
    variant: {
      type: String,
      default: "primary",
      validator: value => HIGHLIGHTVARIANTS.includes(value)
    }
  },
  computed: {
    hasTwoItemsClass() {
      const hasRightItem = Object.prototype.hasOwnProperty.call(
        this.$slots,
        "right"
      );
      return hasRightItem ? "wac-cms-highlightbox--two-items" : "";
    },
    hasRightItem() {
      return this.$slots.right;
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-highlightbox {
  &--two-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(288px, 100%), 1fr));
  }

  &::v-deep {
    .wac-text-media .wac-cms-button {
      display: block;
    }

    .wac-text-media .wac-cms-button:first-child .wac-button {
      margin-top: 25px;
    }

    .wac-text h2 {
      margin-bottom: 25px;
    }

    .wac-text p {
      @include fontSize(16px, 1.5);
    }

    .wac-text-media-link {
      padding-top: 19px;

      .wac-link + .wac-link {
        margin-top: 10px;
      }
    }
  }

  .wac-background--primary,
  .wac-background--dark,
  .wac-background--light {
    padding: 35px 20px 50px;
  }

  @media only screen and (max-width: $bp-medium) {
    div + .wac-background--light {
      margin-top: var(--spacing-300);
    }
  }

  @media only screen and (min-width: $bp-medium) {
    .wac-background--primary,
    .wac-background--dark,
    .wac-background--light {
      padding-right: var(--spacing-300);
      padding-bottom: var(--spacing-600);
      padding-left: var(--spacing-300);
    }
  }
}
</style>
