<template>
  <div class="wac-cms-image-teaser-item">
    <a class="wac-cms-image-teaser-item__link" :href="href" :target="target">
      <!-- @slot for WacCmsImage -->
      <slot name="image" />
      <wac-text
        class="wac-cms-image-teaser-item__headline"
        :content="headlineContent"
      />
      <wac-text class="wac-cms-image-teaser-item__text" :content="text" />
    </a>
  </div>
</template>

<script>
import { WacText } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 */
export default {
  name: "WacCmsImageTeaserItem",
  components: {
    WacText
  },
  props: {
    /** Property headline is used as headline */
    headline: Array,
    /** Property href is used as href link */
    href: String,
    /** Property target is used as link target */
    target: String,
    /** Property text is used as read more text */
    text: Array
  },
  computed: {
    headlineContent() {
      return [{ type: "h3", content: this.headline }];
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-image-teaser-item {
  $self: &;

  position: relative;
  background-color: var(--wac-bgcolor-primary);

  a {
    text-decoration: none;
  }

  &__headline,
  &__text {
    color: var(--wac-forecolor-primary-bg);
    position: absolute;
    width: 100%;
    padding: var(--spacing-150) var(--spacing-200);
  }

  &__headline {
    top: 0;
    background-color: rgba(var(--wac-bgcolor-primary-rgb), 0.9);
  }

  &__text {
    bottom: 0;
    text-align: right;
  }

  &__link {
    display: block;
    min-height: 145px;
    height: 100%;
  }

  &::v-deep {
    .wac-cms-image {
      opacity: 1;
    }

    #{ $self }__headline > * {
      margin-bottom: 0;
    }

    #{ $self }__text > * {
      @include fontSize(15px, 1.33);
      @include textStyle(0, 300);
    }
  }

  &:hover {
    #{ $self }__headline {
      background-color: transparent;
    }

    #{ $self }__text {
      text-decoration: underline;
    }

    &::v-deep {
      .wac-cms-image {
        opacity: 0.1;
      }
    }
  }
}
</style>
