<template>
  <wac-layout>
    <wac-spacing>
      <wac-accordion v-bind="$props" @toggle="onToggle">
        <template #items>
          <slot name="items" />
        </template>
      </wac-accordion>
    </wac-spacing>
  </wac-layout>
</template>
<script>
import { WacLayout, WacAccordion } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

export default {
  name: "WacCmsAccordionModule",
  components: {
    WacLayout,
    WacSpacing,
    WacAccordion
  },
  props: {
    /**
     * Headline text as content array. Headline text is displayed at the top right in the accordion.
     */
    headline: Array,
    /**
     * The named key for the background color of the collapsible items. Choose one color for all items.
     */
    variant: String,
    /**
     * Switch to true if more then one item can be open.
     */
    multiple: Boolean,
    /**
     * Size prop to choose between small or large design variants
     */
    size: String
  },
  methods: {
    onToggle(args) {
      const { state, title } = args;
      // Emit change event with title and state
      this.$emit("change", title, state);
    }
  }
};
</script>
