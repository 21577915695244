<template>
  <a
    :href="url"
    :title="title"
    target="blank"
    class="wac-cms-social-media-link"
  >
    <wac-icon :variant="icon" />
  </a>
</template>

<script>
import { WacIcon } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-icon--default">WacIcon</a>
 */

export default {
  name: "WacCmsSocialMediaLink",
  components: {
    WacIcon
  },
  props: {
    /** The icon key for the link */
    icon: String,
    url: String,
    title: String
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-social-media-link {
  margin-right: var(--spacing-400);

  ::v-deep .wac-icon {
    width: 22px;
    height: 22px;
    color: var(--wac-color-grey-800);
  }
}
</style>
