<template>
  <wac-layout>
    <wac-spacing>
      <wac-cms-highlight-box :variant="variant">
        <template #left><slot name="left"/></template>
        <template #right><slot name="right"/></template>
      </wac-cms-highlight-box>
    </wac-spacing>
  </wac-layout>
</template>

<script>
import { WacLayout } from "wacker-pattern-library";
import WacCmsHighlightBox from "./WacCmsHighlightBox.vue";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";
import { HIGHLIGHTVARIANTS } from "./highlightvariants";
/**
 * Highligth box module description
 */
export default {
  name: "WacCmsHighlightBoxModule",
  components: { WacLayout, WacSpacing, WacCmsHighlightBox },
  props: {
    variant: {
      type: String,
      default: "primary",
      validator: value => HIGHLIGHTVARIANTS.includes(value)
    }
  }
};
</script>
