<template>
  <div class="wac-cms-footer">
    <div class="wac-cms-footer-top">
      <wac-layout>
        <div class="wac-cms-footer-top-inner">
          <slot name="top" />
        </div>
      </wac-layout>
    </div>
    <wac-layout>
      <div class="wac-cms-footer-columns">
        <slot name="columns" />
      </div>
      <div class="wac-cms-footer-bottom">
        <slot name="bottom" />
      </div>
    </wac-layout>
  </div>
</template>
<script>
import { WacLayout } from "wacker-pattern-library";

/**
 * Generic footer component with any number of columns. Basic component for the CMS footer module with currently three columns.
 */
export default {
  name: "WacCmsFooter",
  components: {
    WacLayout,
  },
};
</script>
<style lang="scss" scoped>
.wac-cms-footer {
  background-color: var(--wac-footer-background);
  padding-bottom: var(--spacing-150);

  &::v-deep {
    & #ot-sdk-btn.wac-link.ot-sdk-show-settings {
      border: none;
      color: #333;
      height: 20px;
      word-wrap: normal;
      padding: 0;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        color: #333;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}
.wac-cms-footer-top-inner,
.wac-cms-footer-columns {
  display: flex;
  justify-content: space-between;
}
.wac-cms-footer-columns {
  padding-top: var(--spacing-300);
}
@media only screen and (max-width: $bp-medium) {
  .wac-cms-footer-top-inner,
  .wac-cms-footer-columns {
    display: block;
  }
}
.wac-cms-footer-top {
  border-bottom: solid 2px var(--wac-color-white);
  padding: 22px 0;
  background-color: var(--wac-footer-headline-bg);
}
</style>
