<template>
  <wac-typography
    :class="['wac-cms-social-media-share']"
    element="a"
    variant="body-5"
    :href="processedUrl"
    :title="title"
  >
    <wac-icon :variant="icon" size="large" />
    {{ title }}
  </wac-typography>
</template>

<script>
import { WacIcon, WacTypography } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-icon--default">WacIcon</a>,
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography--default">WacTypography</a>
 */

export default {
  name: "WacCmsSocialMediaShare",
  components: {
    WacIcon,
    WacTypography
  },
  props: {
    icon: String,
    url: String,
    title: String
  },
  computed: {
    processedUrl() {
      // Replace placeholders {url} in sharer links with current location href
      return this.url.replace(/{url}/g, encodeURI(location.href));
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-social-media-share {
  display: flex;
  align-items: center;
  color: var(--wac-color-grey-800);
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: var(--spacing-200);
  }

  ::v-deep .wac-icon {
    margin-right: 8px;

    &-facebook-square {
      color: #32598f;
    }

    &-x {
      color: #333333;
    }

    &-twitter-square {
      color: #35a9d0;
    }

    &-x {
      color: #333333;
    }

    &-linkedin-square {
      color: #4798c6;
    }

    // TODO: Change class to weibo-square, after the correct icon was added to PL
    &-weibo {
      color: #f5c962;
    }

    // TODO: Change class to envelope-square, after the correct icon was added to PL
    &-envelope {
      color: var(--wac-color-grey-600);
    }
  }
}
</style>
