<template>
  <wac-theme :name="name">
    <slot />
  </wac-theme>
</template>
<script>
import { WacTheme } from "wacker-pattern-library";
export default {
  name: "WacCmsTheme",
  components: { WacTheme },
  props: {
    name: String
  }
};
</script>
<style lang="scss" scoped></style>
