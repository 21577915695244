export default {
  methods: {
    // Download file from given url
    async downloadFile(url) {
      // Get the file name from the dowload url
      const filename = url.substring(url.lastIndexOf("/") + 1);

      /** Fetch file from server, set header to no-cors to avoid 'Access-Control-Allow-Origin' error */
      fetch(url, { mode: "no-cors" })
        .then(response => response.blob())
        .then(blob => {
          const objUrl = URL.createObjectURL(blob);

          // Create a new link
          const anchor = document.createElement("a");
          anchor.href = objUrl;
          anchor.download = filename;

          // Append to the DOM
          document.body.appendChild(anchor);

          // Trigger `click` event
          anchor.click();

          // Remove element from DOM
          document.body.removeChild(anchor);

          /**  Releases an existing object URL which was previously created by calling URL.createObjectURL() */
          URL.revokeObjectURL(objUrl);
        })
        .catch(error => console.log(error));
    }
  }
};
