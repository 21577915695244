<template>
  <div class="wac-cms-social-media">
    <!-- @slot for WacCmsSocialMediaLink => json data in WacCmsSocialMediaLink.items.js -->
    <slot name="link" />

    <!-- wac-flyout -->
    <wac-flyout
      v-if="hasShareContent"
      ref="flyout"
      placement="top"
      :width="flyoutWidth"
      :has-arrow="true"
      :show-mobile-full-screen="true"
      :append-to-app="false"
    >
      <template #trigger>
        <wac-icon
          variant="share-old"
          :title="title"
          class="wac-flyout-trigger-icon"
        />
      </template>

      <div class="wac-flyout-content-wrapper">
        <div class="wac-flyout-header">
          <p class="wac-flyout-header-title">{{ headline }}</p>
          <div class="wac-flyout-close" @click="closeFlyout">
            <wac-icon variant="cross" />
          </div>
        </div>

        <div class="wac-flyout-body">
          <!-- @slot for WacCmsSocialMediaShare => json data in WacCmsSocialMediaShare.items.js -->
          <slot name="share" />
        </div>
      </div>
    </wac-flyout>
  </div>
</template>
<script>
import { WacIcon, WacFlyout } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-icon--default">WacIcon</a>,
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-flyout--default">WacFlyout</a>
 */

export default {
  name: "WacCmsSocialMedia",
  components: {
    WacIcon,
    WacFlyout
  },
  props: {
    title: String,
    headline: String
  },
  computed: {
    hasShareContent() {
      return this.$slots.share;
    },
    flyoutWidth() {
      return 380 + "px";
    }
  },
  methods: {
    closeFlyout() {
      this.$refs.flyout.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-social-media::v-deep .wac-flyout {
  &--full {
    overflow: hidden;
  }

  &-content {
    padding: 0;
  }

  &-topbar {
    // Hide incoming topbar without title
    display: none;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: var(--wac-color-grey-150);
    padding: var(--spacing-150) var(--spacing-200);
    color: var(--wac-color-grey-800);

    &-title {
      @include fontSize(28px, 1.25);
      font-family: var(--wac-font-family);
      font-weight: 300;
      margin-bottom: 0;
    }
  }

  &-body {
    padding: var(--spacing-300);
  }

  &-trigger-icon {
    cursor: pointer;
    width: 22px;
    height: 22px;
    color: var(--wac-color-grey-800);
  }

  @media only screen and (min-width: $bp-medium) {
    // Overwrite inline styles for wac-flyout
    margin-bottom: 10px !important;
  }
}
</style>
