<template>
  <div :class="cssContainer">
    <div :class="cssList">
      <slot name="items" />
    </div>
  </div>
</template>

<script>
/**
 * Content slider component description
 */
export default {
  name: "WacCmsContentSlider",
  props: {
    background: {
      type: String,
      default: "light"
    },
    alignment: {
      type: String,
      default: "left"
    }
  },
  computed: {
    cssContainer() {
      return [
        "wac-cms-content-slider",
        { "wac-cms-content-slider-dark": this.background === "dark" }
      ];
    },
    cssList() {
      return [
        "wac-cms-content-list",
        { "wac-cms-content-left": this.alignment === "left" }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-content-slider {
  background-color: #aaaaaa;
  &-dark {
    background-color: #555;
  }
}
.wac-cms-content-left::v-deep {
  .wac-cms-content-slider-item {
    flex-direction: row-reverse;
  }
}
</style>
