<template>
  <wac-cms-footer class="wac-cms-footer-modul">
    <template #top>
      <div class="wac-cms-footer-logo">
        <!-- @slot Slot for logo component. The following example uses the image component with click event handler, but other content is also possible.<br>
        <code><pre>
        [
          {
            "type": "wac-cms-image",
            "props": {
              "url": "assets/wacker.svg",
              "width": 187,
              "height": 42,
              "title": "WACKER"
            },
            "on": {
              "click": "onLogoClick"
            }
          }
        ]
        </pre></code>
        -->
        <slot name="logo" />
      </div>
      <div v-if="headline">
        <wac-text :content="headline" />
      </div>
    </template>
    <template #columns>
      <div
        v-if="!isMobile || hasLeftContent"
        class="wac-cms-footer-column-left"
      >
        <wac-cms-footer-column>
          <!-- @slot Slot for the content of the right column. In the example, the text media component with links is used. The use of other components is possible.<br>
          <code><pre>
          [
            {
              "type": "wac-text-media",
              "props": {
                "content": [
                  {
                    "type": "p",
                    "text": "Als 100%iges Tochterunternehmen ..."
                  }
                ]
              },
              "in": {
                "link": [
                  {
                    "type": "wac-link",
                    "props": {
                      "icon": "angle-right",
                      "text": "Mehr zur Wacker Chemie AG",
                      "href": "https://www.wacker.com"
                    }
                  }
                ]
              }
            }
          ]
          </pre></code>
          -->
          <slot name="left" />
        </wac-cms-footer-column>
      </div>
      <wac-cms-footer-column class="wac-cms-footer-column-middle">
        <div class="wac-cms-footer-column-splitt">
          <div v-if="hasMiddleContent">
            <!-- @slot Slot for the content of the middle column. In the example below, a list is passed to links. Other content can also be positioned here.<br>
            <code><pre>
            [
              {
                "type": "wac-link",
                "props": {
                  "text": "Zertifikate",
                  "href": "#"
                }
              },
              { .. },
              { .. }
            ]
            </pre></code>
            -->
            <slot name="middle" />
          </div>
          <div v-if="isMobile" class="wac-cms-footer-social-left">
            <slot name="social" />
          </div>
        </div>
      </wac-cms-footer-column>
      <div v-if="!isMobile || hasRightContent" :class="cssColumnRight">
        <wac-cms-footer-column>
          <!-- @slot Slot for the content of the right column. Social media links except for details see social slot. Example with text media component.<br>
          <code><pre>
          [
            {
              "type": "wac-text-media",
              "props": {
                "content": [
                  {
                    "type": "p",
                    "text": "Angebote nur für Industrie, Handel .."
                  }
                ]
              }
            }
          ]
          </pre></code>
          -->
          <slot v-if="hasRightContent" name="right" />
          <!-- @slot Slot for social media icons. The component listed in the example currently has no properties because it is still in the concept phase.<br>
          <code><pre>
          [
            {
              "type": "wac-cms-social-media"
            }
          ]
          </pre></code>
          -->
          <slot v-if="!isMobile" name="social" />
        </wac-cms-footer-column>
      </div>
    </template>
    <template #bottom>
      <div class="wac-cms-footer-bottom">
        <wac-text :content="copyright" />
        <!-- @slot Slot for the transfer of individual links together with the copyright information in a line in the lower area of the footer.<br>
        <code><pre>
        [
          {
            "type": "wac-link",
            "props": { "text": "Impressum", "href": "#" }
          },
          {
            "type": "wac-link",
            "props": { "text": "Datenschutz", "href": "#" }
          },
          {
            "type": "wac-link",
            "props": { "text": "Kontakt", "href": "#" }
          }
        ]
        </pre></code>
        -->
        <slot name="link" />
      </div>
    </template>
  </wac-cms-footer>
</template>
<script>
import WacCmsFooter from "../WacCmsFooter/WacCmsFooter.vue";
import WacCmsFooterColumn from "../WacCmsFooter/WacCmsFooterColumn.vue";
import { WacText } from "wacker-pattern-library";
/**
 * Footer module with three content columns for CMS integration. Examples in this section relate to the satellite projects Drawin and Lucky Silicones.
 */
export default {
  name: "WacCmsFooterModul",
  components: {
    WacCmsFooter,
    WacCmsFooterColumn,
    WacText
  },
  props: {
    /**
     * Headline text as content array. Headline text is displayed at the top right in the footer.<br>
     * <code><pre>
     * [
     *  {
     *   "type": "strong",
     *   "text": "CREATING TOMORROW'S SOLUTIONS"
     *  }
     * ]
     * </pre></code>
     */
    headline: Array,
    /**
     * Copyright information as a content array is displayed at the bottom left in the footer.<br>
     * <code><pre>
     * [
     *   {
     *     "type": "p",
     *     "text": "&copy; 2021 DRAWIN Vertriebs-GmbH"
     *   }
     * ]
     * </pre></code>
     */
    copyright: Array
  },
  data() {
    return {
      width: 0,
      height: 0
    };
  },
  computed: {
    isMobile() {
      return this.width < 576;
    },
    hasLeftContent() {
      return this.$slots.left;
    },
    hasMiddleContent() {
      return this.$slots.middle;
    },
    hasRightContent() {
      return this.$slots.right;
    },
    cssColumnRight() {
      const result = ["wac-cms-footer-column-right"];
      if (this.hasRightContent) result.push("wac-cms-media-space");
      return result;
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-footer-modul::v-deep {
  .wac-link {
    display: block;
  }
  .wac-text p {
    font-size: 14px;
    line-height: 1.43;
  }
}
.wac-cms-footer-column-middle::v-deep {
  .wac-link {
    line-height: 20px;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 6px;
    cursor: pointer;
  }
}
.wac-cms-footer-column-right {
  text-align: right;
}
.wac-cms-footer-column-splitt {
  display: flex;
  justify-content: space-between;
}
.wac-cms-footer-column-splitt::v-deep {
  .wac-cms-social-media {
    text-align: right;
  }
}
.wac-cms-footer-logo {
  width: 126px;
}
@media only screen and (min-width: $bp-medium) {
  .wac-cms-footer-column-left::v-deep {
    .wac-text {
      max-width: 360px;
    }
  }
  .wac-cms-footer-column-middle {
    margin: 0 var(--spacing-400);
  }
  .wac-cms-footer-column-right {
    max-width: 275px;
  }

  .wac-cms-footer-column-right::v-deep {
    .wac-text {
      max-width: 260px;
      display: flex;
      margin-left: auto;
    }
  }
  .wac-cms-footer-column-right.wac-cms-media-space::v-deep {
    .wac-cms-social-media {
      padding-top: 34px;
    }
  }
}
@media only screen and (max-width: $bp-medium) {
  .wac-cms-footer-column-right {
    text-align: left;
  }
  .wac-cms-footer-column-middle {
    margin-top: 10px;
  }
  .wac-cms-footer-logo {
    margin-bottom: 18px;
  }
}
.wac-cms-footer-bottom {
  margin-bottom: -10px;
}
.wac-cms-footer-bottom::v-deep {
  .wac-text,
  .wac-link {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-right: 34px;
    margin-bottom: 10px;
  }
  .wac-text p {
    margin: 0;
  }
}
.wac-cms-footer-social-left::v-deep {
  .wac-cms-social-media .wac-icon:first-child {
    margin-left: 0;
  }
}
</style>
