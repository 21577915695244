<template>
  <div class="wac-cms-content-slider-item">
    <div class="wac-cms-content-slider-content">
      <wac-text :content="headlineContent" />
      <wac-text :content="content" />

      <!-- @slot Description for link slot -->
      <slot name="link" />
      <!-- @slot Description for button slot -->
      <slot name="button" />
    </div>
    <div class="wac-cms-content-slider-media">
      <!-- @slot Describes the media slot <br> 
      <code><pre>
      media: [
        {
          "type": "wac-cms-image",
          "props": {
            "url": "assets/looking_through_microscope_480x320.jpg",
            "width": 480,
            "height": 320,
            "title": "Hello World",
            "alt": "Alternate Text"
          }
        }
      ]
      </pre></code>
      -->
      <slot name="media" />
    </div>
  </div>
</template>

<script>
import { WacText } from "wacker-pattern-library";
/**
 * Description for component
 */
export default {
  name: "WacCmsContentSliderItem",
  components: {
    WacText
  },
  props: {
    /** Headline text as content array<br>
     * <i>Example data with non breaking spaces and superscript copyright</i>
     * <code style="width:100%"><pre>
     * "headline": [
     *   {
     *     type: "text",
     *     text: "Headline text"
     *   },
     *   {
     *     type: "nbsp"
     *   },
     *   {
     *     type: "sup",
     *     text: "&copy; x57s"
     *   }
     * ]
     * </pre></code>
     */
    headline: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    /** Descriptive text as content array<br>
     * <i>Example data with paragraph, a simple sub headline and numbered list with italic and bold emphasis</i>
     * <code style="width:100%"><pre>
     * content: [
     *   {
     *     type: "p",
     *     text:
     *       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
     *   },
     *   {
     *     type: "h3",
     *     text: "Sub headline"
     *   },
     *   {
     *     type: "ol",
     *     content: [
     *       {
     *         type: "li",
     *         text: "First point"
     *       },
     *       {
     *         type: "li",
     *         content: [
     *           {
     *             type: "i",
     *             text: "Second "
     *           },
     *           {
     *             type: "strong",
     *             text: "point in bold"
     *           }
     *         ]
     *       },
     *       {
     *         type: "li",
     *         text: "Third point"
     *       }
     *     ]
     *   },
     *   {
     *     type: "p",
     *     text: "Last paragraph"
     *   }
     *]
     * </pre></code>
     */
    content: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    headlineContent() {
      return [
        {
          type: "h2",
          content: this.headline
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-content-slider {
  &-item {
    display: flex;
    flex-direction: row;
  }
  &-content {
    padding: 20px;
    width: 30%;
  }
  &-media {
    width: 70%;
  }
}
.wac-cms-content-slider-content ::v-deep {
  h2 {
    color: white;
  }
  h2::after {
    content: "A";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 2px;
    background-color: #fff;
    box-sizing: border-box;
  }
}
</style>
