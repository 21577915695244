<template>
  <div class="wac-cms-image-text-teaser-item">
    <a
      ref="outerLink"
      class="wac-cms-image-text-teaser-item__link"
      :href="linkContent.href"
      :target="linkContent.target"
    >
      <!-- @slot for WacCmsImage -->
      <slot name="image" />
      <div class="wac-cms-image-text-teaser-item__body">
        <wac-text
          v-if="headline"
          class="wac-cms-image-text-teaser-item__headline"
          :content="headlineContent"
        />
        <wac-text
          class="wac-cms-image-text-teaser-item__content"
          :content="content"
        />
        <!-- @slot for WacLink -->
        <slot name="link" />
      </div>
    </a>
  </div>
</template>

<script>
import { WacText } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 */
export default {
  name: "WacCmsImageTextTeaserItem",
  components: {
    WacText
  },
  props: {
    /** Property headline is used as headline */
    headline: Array,
    /** Property content is used as content text */
    content: Array
  },
  data() {
    return {
      linkContent: {
        href: "",
        target: ""
      }
    };
  },
  computed: {
    headlineContent() {
      return [{ type: "h3", content: this.headline }];
    }
  },
  mounted() {
    const innerLink = this.$refs.outerLink.querySelector("a");
    // Get href property from WacLink from slot
    this.linkContent.href = innerLink ? innerLink.href || "#" : "#";
    this.linkContent.target = innerLink ? innerLink.target || "" : "";
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-image-text-teaser-item {
  $self: &;

  a {
    text-decoration: none;
  }

  &::v-deep {
    .wac-cms-image {
      margin-bottom: var(--spacing-200);
    }
    .wac-link {
      margin-bottom: var(--spacing-300);
    }
    #{ $self }__headline > * {
      @include fontSize(26px, 1.15);
      @include textStyle(var(--spacing-100), 300);
    }
    #{ $self }__content > * {
      @include fontSize(14px, 1.43);
    }
  }

  &:hover {
    &::v-deep {
      .wac-link {
        text-decoration: underline;
      }
    }
  }
}
</style>
