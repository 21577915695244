<template>
  <wac-layout>
    <wac-spacing>
      <wac-cms-image-teaser :headline="headline">
        <template #items>
          <slot name="items" />
        </template>
      </wac-cms-image-teaser>
    </wac-spacing>
  </wac-layout>
</template>
<script>
import WacCmsImageTeaser from "../WacCmsImageTeaser/WacCmsImageTeaser.vue";
import { WacLayout } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

export default {
  name: "WacCmsImageTeaserModule",
  components: {
    WacLayout,
    WacCmsImageTeaser,
    WacSpacing
  },
  props: {
    /** Property headline is used as headline for the image teaser module (optional) */
    headline: Array
  }
};
</script>
