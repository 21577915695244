<template>
  <wac-layout>
    <wac-spacing>
      <wac-cms-include v-bind="$props" />
    </wac-spacing>
  </wac-layout>
</template>
<script>
import { WacLayout } from "wacker-pattern-library";
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";
import WacCmsInclude from "./WacCmsInclude.vue";

export default {
  name: "WacCmsIncludeModule",
  components: {
    WacLayout,
    WacSpacing,
    WacCmsInclude
  },
  props: {
    el: String
  }
};
</script>
