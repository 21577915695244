<template>
  <div class="wac-cms-form-modal">
    <wac-cms-button
      v-if="type === 'button'"
      :text="text"
      :icon="icon"
      :title="title"
      href="#"
      @click.stop="showModal = true"
    />
    <wac-link
      v-if="type === 'link'"
      :text="text"
      :icon="icon"
      :title="title"
      href="#"
      @click.prevent="showModal = true"
    />
    <wac-modal v-if="showModal" :title="title || ''" @close="showModal = false">
      <template v-slot:modalContent>
        <slot name="modalContent">
          <wac-cms-form-include :source="source" :presets="presets" />
        </slot>
      </template>
    </wac-modal>
  </div>
</template>

<script>
/* <slot name="modalContent" /> */
import { WacModal, WacLink } from "wacker-pattern-library";
import WacCmsFormInclude from "../WacCmsFormInclude/WacCmsFormInclude.vue";
import WacCmsButton from "../WacCmsButton/WacCmsButton.vue";

export default {
  components: {
    WacModal,
    WacLink,
    WacCmsFormInclude,
    WacCmsButton,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    text: String,
    title: String,
    icon: String,
    source: String,
    presets: Object,
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    showModal: (newValue) => {
      if (newValue === true) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wac-cms-form-modal {
  display: inline-block;
  vertical-align: top;
}
.wac-cms-form-modal::v-deep {
  .wac-text,
  .wac-typo,
  .wac-modal-topbar .wac-icon {
    color: var(--wac-forecolor-white-bg);
  }
  .wac-text {
    text-align: left;
  }
  .wac-modal {
    position: absolute;
    z-index: var(--z-index-2250);
  }
}
</style>
