<template>
  <wac-background class="wac-header-meta" variant="light">
    <wac-layout>
      <slot name="meta" />
    </wac-layout>
  </wac-background>
</template>

<script>
import { WacLayout, WacBackground } from "wacker-pattern-library";

export default {
  name: "WacCmsHeaderMetaNav",
  components: {
    WacLayout,
    WacBackground
  }
};
</script>

<style lang="scss" scoped>
.wac-header-meta {
  text-align: right;
}

.wac-header-meta::v-deep {
  .wac-cms-form-modal * {
    text-align: left;
  }
}

.wac-header-meta::v-deep {
  .wac-typo.wac-link {
    @include fontSize(14px, 2.36);
    font-weight: 400;
    vertical-align: middle;
    margin-left: var(--spacing-300);
    color: rgb(var(--wac-color-grey-650-rgb));
  }

  .wac-icon {
    width: 15px;
    height: 15px;
  }
}
</style>
