<template>
  <div class="wac-cms-download">
    <wac-text
      v-if="headline"
      class="wac-cms-download__headline"
      :content="headlineContent"
    />
    <wac-documents :documents="processedItems" @download="onDownload" />
  </div>
</template>

<script>
import downloadFile from "../../../mixins/downloadFile.js";
import { WacText, WacDocuments } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-ic-modules-document-download-list--default">WacDocuments</a>
 */
export default {
  name: "WacCmsDownload",
  components: {
    WacText,
    WacDocuments
  },
  mixins: [downloadFile],
  props: {
    /** Property headline is used as headline for the download module (optional) */
    headline: Array,
    /** Property items is used as download items array */
    items: Array
  },
  computed: {
    headlineContent() {
      return [{ type: "h2", content: this.headline }];
    },
    processedItems() {
      return this.processItems(this.items);
    }
  },
  methods: {
    // Transform fs items data model to the WacDocument data model
    processItems(itemsArr) {
      return itemsArr.map(item => ({
        name: item.title,
        description: item.description,
        documents: [
          {
            displayText: item.label,
            icon: "download",
            url: item.url
          }
        ],
        tracking: item.tracking
      }));
    },
    onDownload(...[item, , event]) {
      // Emit download event with event and item
      this.$emit("download", event, item);

      // Download file from given url
      this.downloadFile(item.documents[0].url);
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-download {
  $self: &;

  &::v-deep {
    #{ $self }__headline > * {
      margin-bottom: var(--spacing-300);
    }

    .wac-documents {
      padding-top: var(--spacing-150);
      border-top: 1px solid var(--wac-color-grey-350);
    }

    .wac-document {
      margin-bottom: var(--spacing-150);

      &__generic {
        padding-top: 0;
        padding-bottom: var(--spacing-150);
      }

      &__name {
        @include fontSize(16px, 1.25);
      }

      &__description {
        @include fontSize(13px, 1.5);
        color: var(--wac-color-grey-650);
      }

      &__info--inner,
      &__name,
      &__description {
        display: inline;
      }
    }
  }
}
</style>
