<template>
  <div
    ref="header"
    :class="[
      'wac-header-module',
      !navigationOpen ? 'isNavClosed' : '',
      isSticky ? 'wac-header-module--isSticky' : ''
    ]"
  >
    <wac-cms-header-meta-nav v-if="isDesktop" v-bind="inner.meta">
      <template #meta>
        <slot name="meta" />
      </template>
    </wac-cms-header-meta-nav>
    <wac-layout>
      <div class="wac-header">
        <div><slot name="left" /></div>
        <div v-if="isDesktop || isMobile">
          <slot v-if="isDesktop && !isSticky" name="right" />
          <a
            v-if="isMobile || isSticky"
            href="#"
            class="wac-link"
            @click.stop.prevent="toggleNavigation"
          >
            <wac-icon v-if="navigationOpen" variant="cross" size="large" />
            <wac-icon v-else variant="menu" size="large" />
          </a>
        </div>
      </div>
    </wac-layout>
    <div v-show="isDesktop || navigationOpen" class="wac-header-navigation">
      <wac-layout>
        <wac-cms-navigation
          :layout="currentLayout"
          :root="root"
          v-bind="inner.navi"
          @goTo="onGoTo"
        >
          <template #children>
            <slot name="navi" />
          </template>
        </wac-cms-navigation>
        <div
          v-if="isMobile"
          class="wac-header-meta__mobile"
          v-bind="inner.meta"
        >
          <slot name="meta" />
        </div>
      </wac-layout>
    </div>
  </div>
</template>

<script>
import WacCmsHeaderMetaNav from "./WacCmsHeaderMetaNav.vue";
import WacCmsNavigation from "../WacCmsNavigation/WacCmsNavigation.vue";
import { WacIcon, WacLayout } from "wacker-pattern-library";
import { isMobileView, isDektopView } from "../../../mixins/viewportCheck.js";
import { debounce } from "debounce";

export default {
  name: "WacCmsHeader",
  components: {
    WacLayout,
    WacCmsNavigation,
    WacIcon,
    WacCmsHeaderMetaNav
  },
  props: {
    layout: {
      type: String,
      default: "mobile"
    },
    root: {
      type: String,
      default: "Home"
    },
    /** Property inner is used to pass tracking attributes to meta and main navigation */
    inner: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      bodyHeight: 0,
      headerHeight: 0,
      navigationOpen: false,
      isSticky: false,
      // value to scroll before toggle sticky header class on mobile
      scrollAmountMobile: 1,
      // value to scroll before toggle sticky header class on tablet/desktop
      scrollAmountDesktop: 10
    };
  },
  computed: {
    isMobile() {
      return isMobileView(this.currentLayout);
    },
    isDesktop() {
      return isDektopView(this.currentLayout);
    },
    currentLayout() {
      return this.layout !== "auto" ? this.layout : this.screenLayout;
    },
    screenLayout() {
      return this.screenWidth < 992 ? "mobile" : "desktop";
    },
    currentScrollAmount() {
      return this.screenLayout === "mobile"
        ? this.scrollAmountMobile
        : this.scrollAmountDesktop;
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this code will run after the entire view has been rendered

      // get height of the header element
      this.headerHeight = this.$refs.header.clientHeight;

      // get height of the entire body element
      this.bodyHeight = window.document.body.clientHeight;
    });
  },
  created() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    toggleNavigation() {
      this.navigationOpen = !this.navigationOpen;
      this.checkBodyClass();
    },
    shouldSticky() {
      // to prevent the flickering issue #113372
      // sticky header should only be applied if there is enough space to scroll
      return (
        this.bodyHeight - this.headerHeight - this.currentScrollAmount >
        this.screenHeight
      );
    },
    toggleSticky(scrollY) {
      // check whether the body height has changed
      if (this.bodyHeight !== window.document.body.clientHeight)
        this.bodyHeight = window.document.body.clientHeight;

      // toggle sticky header
      if (this.shouldSticky()) {
        this.isSticky = scrollY > this.currentScrollAmount;
      }

      // force back the "large" header when scrolling back to top
      if (this.isSticky && scrollY < this.currentScrollAmount) {
        this.isSticky = false;
      }
    },
    onGoTo() {
      this.navigationOpen = false;
      this.checkBodyClass();
    },
    onResize() {
      if (this.screenWidth !== window.innerWidth)
        this.screenWidth = window.innerWidth;

      if (this.screenHeight !== window.innerHeight)
        this.screenHeight = window.innerHeight;
    },
    onScroll: debounce(function() {
      this.toggleSticky(window.scrollY);
    }, 10),
    checkBodyClass() {
      /**
       * for mobile: if navigation is open (which means active), then the body needs
       * an additional class to prevent the content scrolling
       */
      if (this.isMobile) {
        const htmlBody = document.body.classList;
        const activeClass = "wac-mobile-nav-open";
        this.navigationOpen
          ? htmlBody.add(activeClass)
          : htmlBody.remove(activeClass);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-header-module {
  background-color: var(--wac-color-white);

  /* STICKY HEADER */
  &--isSticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2050;

    &.isNavClosed {
      box-shadow: 0 2px 5px 0 rgba(var(--wac-color-black-rgb), 0.15);
    }

    @media only screen and (min-width: $bp-upper-medium) {
      .wac-header {
        transition: height ease-in-out 200ms;
        height: 63px;

        &::v-deep {
          .wac-cms-image {
            width: 78%;
          }
        }

        // Hide meta navigation
        &-meta {
          display: none;
        }
      }

      &.isNavClosed {
        .wac-header-navigation {
          display: none;
        }
      }
    }
  }
}

.wac-header-meta-mobile::v-deep,
.wac-header::v-deep {
  .wac-link {
    display: block;
  }
}
.wac-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;

  &::v-deep {
    .wac-cms-image {
      width: 78%;
    }

    .wac-link {
      color: rgb(var(--wac-color-grey-800-rgb));
    }
    .wac-icon {
      width: 25px;
      height: 25px;
    }
  }
}

.wac-header-navigation {
  border-top: solid 1px var(--wac-color-grey-300);
}

@media only screen and (min-width: $bp-large) {
  .wac-header::v-deep {
    height: 92px;

    .wac-cms-image {
      width: 100%;
    }
  }

  .wac-header-navigation {
    border-top-width: 2px;
    border-bottom: solid 2px var(--wac-color-grey-300);
  }
}

.wac-header-meta__mobile::v-deep {
  margin-top: 30px;

  .wac-typo.wac-link {
    display: block;
    margin-bottom: var(--spacing-200);
    @include fontSize(14px, 1.43);
    font-weight: 400;
    color: rgb(var(--wac-color-grey-650-rgb));
  }

  .wac-icon {
    float: left;
    width: 15px;
    height: 15px;
    margin-top: 2px;
    color: rgb(var(--wac-color-grey-800-rgb));
  }
}
</style>

<style lang="scss">
// this styles need to be unscoped to reach the body outside of this component
@media only screen and (max-width: $bp-large) {
  body.wac-mobile-nav-open {
    overflow-y: hidden;

    .wac-header-navigation {
      height: calc(100vh - 63px);

      > .wac-layout {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}
</style>
