<template>
  <wac-background variant="white">
    <wac-layout>
      <wac-spacing>
        <wac-cms-download v-bind="$props" @download="onDownload" />
      </wac-spacing>
    </wac-layout>
  </wac-background>
</template>

<script>
import WacCmsDownload from "./WacCmsDownload.vue";
import { WacLayout, WacBackground } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-layout-page--default">WacLayout</a>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-theming-background--with-typography">WacBackground</a>
 */
export default {
  name: "WacCmsDownloadModule",
  components: {
    WacBackground,
    WacLayout,
    WacSpacing,
    WacCmsDownload
  },
  props: {
    /** Property headline is used as headline for the download module (optional) */
    headline: Array,
    /** Property items is used as download items array */
    items: Array
  },
  methods: {
    onDownload(event, item) {
      // Emit download event with event and item
      this.$emit("download", event, item);
    }
  }
};
</script>
