import WacCmsContentSlider from "../components/cms/WacCmsContentSlider/WacCmsContentSlider.vue";
import WacCmsContentSliderItem from "../components/cms/WacCmsContentSlider/WacCmsContentSliderItem.vue";
import WacCmsTextMediaModule from "../components/cms/WacCmsTextMediaModule/WacCmsTextMediaModule.vue";
import WacCmsTextMediaList from "../components/cms/WacCmsTextMediaList/WacCmsTextMediaList.vue";
import WacCmsImage from "../components/cms/WacCmsImage/WacCmsImage.vue";
import WacCmsImageWithZoom from "../components/cms/WacCmsImageWithZoom/WacCmsImageWithZoom.vue";
import WacCmsVideo from "../components/cms/WacCmsVideo/WacCmsVideo.vue";
import WacCmsIntroBox from "../components/cms/WacCmsIntroBox/WacCmsIntroBox.vue";
import WacCmsAccordionModule from "../components/cms/WacCmsAccordionModule/WacCmsAccordionModule.vue";
import WacCmsButton from "../components/cms/WacCmsButton/WacCmsButton.vue";
import WacCmsFooterModul from "../components/cms/WacCmsFooterModul/WacCmsFooterModul.vue";
import WacCmsSocialMedia from "../components/cms/WacCmsSocialMedia/WacCmsSocialMedia.vue";
import WacCmsStageModul from "../components/cms/WacCmsStageModul/WacCmsStageModul.vue";
import WacCmsSocialMediaLink from "../components/cms/WacCmsSocialMedia/WacCmsSocialMediaLink.vue";
import WacCmsSocialMediaShare from "../components/cms/WacCmsSocialMedia/WacCmsSocialMediaShare.vue";
import WacCmsHeaderModule from "../components/cms/WacCmsHeaderModule/WacCmsHeaderModule.vue";
import WacCmsNavigationItem from "../components/cms/WacCmsNavigation/WacCmsNavigationItem.vue";
import WacCmsImageTeaserItem from "../components/cms/WacCmsImageTeaser/WacCmsImageTeaserItem.vue";
import WacCmsImageTeaser from "../components/cms/WacCmsImageTeaser/WacCmsImageTeaser.vue";
import WacCmsImageTeaserModule from "../components/cms/WacCmsImageTeaser/WacCmsImageTeaserModule.vue";
import WacCmsBreadcrumbModule from "../components/cms/WacCmsBreadcrumb/WacCmsBreadcrumbModule.vue";
import WacCmsBackToTopButton from "../components/cms/WacCmsBackToTopButton/WacCmsBackToTopButton.vue";
import WacCmsImageTextTeaserItem from "../components/cms/WacCmsImageTextTeaser/WacCmsImageTextTeaserItem.vue";
import WacCmsImageTextTeaser from "../components/cms/WacCmsImageTextTeaser/WacCmsImageTextTeaser.vue";
import WacCmsImageTextTeaserModule from "../components/cms/WacCmsImageTextTeaser/WacCmsImageTextTeaserModule.vue";
import WacCmsHighlightBox from "../components/cms/WacCmsHighlightBox/WacCmsHighlightBox.vue";
import WacCmsHighlightBoxModule from "../components/cms/WacCmsHighlightBox/WacCmsHighlightBoxModule.vue";
import WacCmsProductTeaserModule from "../components/cms/WacCmsProductTeaser/WacCmsProductTeaserModule.vue";
import WacCmsProductTeaserItem from "../components/cms/WacCmsProductTeaser/WacCmsProductTeaserItem.vue";
import WacCmsProductTeaser from "../components/cms/WacCmsProductTeaser/WacCmsProductTeaser.vue";
import WacCmsTagFilterModule from "../components/cms/WacCmsTagFilterModule/WacCmsTagFilterModule.vue";
import WacCmsDownload from "../components/cms/WacCmsDownload/WacCmsDownload.vue";
import WacCmsDownloadModule from "../components/cms/WacCmsDownload/WacCmsDownloadModule.vue";
import WacCmsProductSelectorModule from "../components/cms/WacCmsProductSelector/WacCmsProductSelectorModule.vue";
import WacCmsProductSelect from "../components/cms/WacCmsProductSelector/WacCmsProductSelect.vue";
import WacCmsInclude from "../components/cms/WacCmsInclude/WacCmsInclude.vue";
import WacCmsIncludeModule from "../components/cms/WacCmsInclude/WacCmsIncludeModule.vue";
import WacCmsFormModule from "../components/cms/WacCmsFormModule/WacCmsFormModule.vue";
import WacCmsFormModal from "../components/cms/WacCmsFormModal/WacCmsFormModal.vue";
import WacCmsCookiePolicyListModule from "../components/cms/WacCmsCookiePolicyListModule/WacCmsCookiePolicyListModule.vue";
import WacCmsCastingMoldEstimator from "../components/cms/WacCmsEstimators/WacCmsCastingMoldEstimator.vue"
import WacCmsSingleTeaserModule from "../components/cms/WacCmsSingleTeaser/WacCmsSingleTeaserModule.vue";
import WacCmsSkinMoldEstimator from "../components/cms/WacCmsEstimators/WacCmsSkinMoldEstimator.vue"

// Pattern lib components
import {
  WacButton,
  WacLink,
  WacBreadcrumb,
  WacIcon,
  WacTypography,
  WacBackground,
  WacTheme,
  WacText,
  WacTextMedia,
  WacIconButton,
  WacLayout,
  WacDocuments,
  WacSelect,
  WacAccordion,
  WacAccordionItem,
  WacMediaPlayer,
  WacInput,
} from "wacker-pattern-library";

// Form Framework
import { WacCmsForm } from "wacker-formframework";

export default {
  components: {
    WacCmsContentSlider,
    WacCmsContentSliderItem,
    WacCmsTextMediaModule,
    WacCmsTextMediaList,
    WacCmsImage,
    WacCmsImageWithZoom,
    WacCmsVideo,
    WacCmsIntroBox,
    WacCmsAccordionModule,
    WacCmsButton,
    WacCmsSocialMedia,
    WacCmsStageModul,
    WacCmsSocialMediaLink,
    WacCmsSocialMediaShare,
    WacCmsHeaderModule,
    WacCmsNavigationItem,
    WacCmsImageTeaserItem,
    WacCmsImageTeaser,
    WacCmsImageTeaserModule,
    WacCmsBreadcrumbModule,
    WacCmsBackToTopButton,
    WacCmsImageTextTeaserItem,
    WacCmsImageTextTeaser,
    WacCmsImageTextTeaserModule,
    WacCmsHighlightBox,
    WacCmsHighlightBoxModule,
    WacCmsProductTeaserModule,
    WacCmsProductTeaserItem,
    WacCmsProductTeaser,
    WacCmsTagFilterModule,
    WacCmsDownload,
    WacCmsDownloadModule,
    WacCmsProductSelectorModule,
    WacCmsProductSelect,
    WacCmsInclude,
    WacCmsIncludeModule,
    WacCmsFormModule,
    WacCmsFormModal,
    WacCmsCookiePolicyListModule,
    WacCmsCastingMoldEstimator,
    WacCmsSingleTeaserModule,
    WacCmsSkinMoldEstimator,
    // Pattern lib components
    WacButton,
    WacLink,
    WacTypography,
    WacBreadcrumb,
    WacIcon,
    WacCmsFooterModul,
    WacBackground,
    WacTheme,
    WacText,
    WacTextMedia,
    WacIconButton,
    WacLayout,
    WacDocuments,
    WacSelect,
    WacAccordion,
    WacAccordionItem,
    WacMediaPlayer,
    WacInput,
    // Form Framework
    WacCmsForm
  }
};
