<template>
  <div class="wac-cms-product-select">
    <wac-select
      :options="processedItems"
      :has-search="true"
      :placeholder="placeholder"
      width="345px"
    >
      <template #default="{ option }">
        <a
          v-bind="option"
          :text="null"
          :name="undefined"
          :value="undefined"
          target="_self"
          @click.stop
        >
          {{ option.text }}
        </a>
      </template>
    </wac-select>
  </div>
</template>

<script>
import { WacSelect } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-molecules-select--with-search">WacSelect</a>
 */

export default {
  name: "WacCmsProductSelect",
  components: {
    WacSelect
  },
  props: {
    /** Property placeholder is used as placeholder text */
    placeholder: String,
    /** Property noMatch is used as message to display if no products were found */
    noMatch: String,
    /** Property items is used as products array */
    items: Array
  },
  computed: {
    processedItems() {
      return this.processItems(this.items);
    }
  },
  methods: {
    // Transform fs items data model to the WacSelect data model
    processItems(itemsArr) {
      return itemsArr.map(item => ({
        ...item,
        ...{
          text: item.name,
          value: item.name
        }
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-product-select {
  &::v-deep {
    .wac-icon {
      color: var(--wac-color-grey-800);
    }
    .wac-select {
      text-align: left;
      border: none;
    }
    .wac-option {
      padding: 0;
      > a {
        padding: 9px 15px;
        text-decoration: none;
        color: var(--wac-color-grey-800);
        width: 100%;
        text-align: left;
      }
    }
  }
}
</style>
