<template>
  <wac-background :variant="variant">
    <wac-layout>
      <wac-cms-breadcrumb v-bind="$props" :variant="null"></wac-cms-breadcrumb>
    </wac-layout>
  </wac-background>
</template>

<script>
import { WacLayout, WacBackground } from "wacker-pattern-library";
import WacCmsBreadcrumb from "./WacCmsBreadcrumb.vue";

export default {
  name: "WacCmsBreadcrumbModule",
  components: {
    WacBackground,
    WacLayout,
    WacCmsBreadcrumb
  },
  props: {
    /** Property items is used as breadcrumb items array */
    items: {
      type: Array,
      required: true
    },
    variant: {
      type: String,
      default: "primary"
    }
  }
};
</script>
