<template>
  <div class="wac-cms-product-selector">
    <div class="wac-cms-product-selector__bg">
      <wac-text
        v-if="headline"
        class="wac-cms-product-selector__headline"
        :content="headlineContent"
      />
      <wac-icon class="wac-cms-product-selector__bg-icon" variant="loupe" />
      <!-- @slot for WacCmsProductSelect  -->
      <slot name="search" />
    </div>
  </div>
</template>

<script>
import { WacText, WacIcon } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/components-atoms-icon--default">WacIcon</a>
 */

export default {
  name: "WacCmsProductSelector",
  components: {
    WacText,
    WacIcon
  },
  props: {
    /** Property headline is used as headline */
    headline: Array
  },
  computed: {
    headlineContent() {
      return [{ type: "h2", content: this.headline }];
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-product-selector {
  $self: &;

  text-align: center;
  padding-top: var(--spacing-200);
  padding-bottom: var(--spacing-300);

  &__bg-icon {
    display: none;
  }

  &::v-deep {
    #{ $self }__headline > * {
      @include fontSize(30px, 1.2);
      margin-bottom: var(--spacing-200);
      padding-top: 5px;
    }
  }

  @media only screen and (min-width: $bp-large) {
    padding-top: var(--spacing-300);
    padding-bottom: var(--spacing-600);

    #{ $self }__bg {
      width: 580px;
      position: relative;
      margin: 0 auto;
    }

    #{ $self }__bg-icon {
      display: block;
      width: 122px;
      height: 122px;
      position: absolute;
      bottom: -11px;
      left: -7px;
      opacity: 0.1;
    }

    &::v-deep {
      #{ $self }__headline > * {
        @include fontSize(35px, 1.09);
        margin-bottom: var(--spacing-300);
      }
    }
  }
}
</style>
