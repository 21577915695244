<template>
  <div>
    <wac-cms-consent-notification v-if="!hasConsent" />

    <wac-media-player
      v-else
      class="wac-cms-video"
      v-bind="$props"
      @source-selected="onSourceSelected"
      @media-ready="onMediaReady"
    />
  </div>
</template>

<script>
import { WacMediaPlayer } from "wacker-pattern-library";
import WacCmsConsentNotification from "../WacCmsConsentNotification/WacCmsConsentNotification.vue";

export default {
  name: "WacCmsVideo",
  components: {
    WacMediaPlayer,
    WacCmsConsentNotification,
  },
  props: {
    /**
     * The Kaltura partner id.
     */
    partnerId: {
      type: Number,
      required: true,
    },
    /**
     * The kaltura ui config id.
     */
    uiconfId: {
      type: Number,
      required: true,
    },
    /**
     * The kaltura media id.
     */
    entryId: {
      type: String,
      required: true,
    },
    /**
     * The thumbnail width as a number. The height will be calculated by the given aspect ratio.
     */
    thumbnailWidth: {
      type: Number,
      // Default width from wacker.com implementations
      default: 1600,
    },
  },
  data() {
    return {
      hasConsent: false,
    };
  },
  created() {
    if (typeof window.cookieMonster === "object") {
      if (window.cookieMonster.permitted("fnct")) {
        console.log("cookieMonster permitted fnct");
        this.hasConsent = true;
      } else {
        // wait for change on consent
        window.cookieMonster.once("fnct", () => {
          console.log("cookieMonster once fnct");
          this.hasConsent = true;
        });
      }
    } else {
      console.warn("No cookieMonster available");
      // cookie usage is forbidden (cookieMonster is unavailable)
      // What should we do here???
      // TODO: 130393
      this.hasConsent = true
    }
  },
  methods: {
    onSourceSelected(source) {
      this.$emit("source-selected", source);
    },

    onMediaReady() {
      this.$emit("media-ready");
    },
  },
};
</script>
