<template>
  <wac-layout>
    <wac-spacing>
      <div class="wac-cms-single-teaser">
        <wac-text v-if="moduleHeadline" :content="moduleHeadline" />
        <wac-text v-if="moduleContent" :content="moduleContent" />
        <div class="wac-cms-single-teaser__container">
          <div class="wac-cms-single-teaser__image-container">
            <slot name="image" />
          </div>
          <div class="wac-cms-single-teaser__text-container">
            <wac-text v-if="sectionHeadline" :content="sectionHeadline" />
            <wac-text v-if="sectionContent" :content="sectionContent" />
            <slot name="cta" />
          </div>
        </div>
      </div>
    </wac-spacing>
  </wac-layout>
</template>

<script>
import { WacLayout, WacText } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";

export default {
  name: "WacCmsSingleTeaserModule",
  components: {
    WacLayout,
    WacSpacing,
    WacText,
  },
  props: {
    /** Property moduleHeadline is used as headline for the image text teaser module (optional) */
    moduleHeadline: {
      type: Array,
      required: false,
    },

    /** Property moduleContent is used as content for the image text teaser module (optional) */
    moduleContent: {
      type: Array,
      required: false,
    },

    /** Property sectionHeadline is used as section headline for the image text teaser module (optional) */
    sectionHeadline: {
      type: Array,
      required: false,
    },

    /** Property sectionContent is used as section content for the image text teaser module (optional) */
    sectionContent: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wac-cms-single-teaser {
  &__container {
    display: flex;
    gap: 50px;
    margin-top: 60px;

    .wac-spacing-reduce-top & {
      margin-top: 0;
    }
  }

  &__image-container {
    width: 40%;
  }

  &__text-container {
    width: 60%;

    &::v-deep {
      h3 {
        font-size: 26px;
        line-height: 30px;
        font-weight: 300;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}
</style>
