<template>
  <wac-cms-header :root="root" layout="auto" :inner="inner">
    <template #meta>
      <slot name="meta" />
    </template>
    <template #left>
      <slot name="left" />
    </template>
    <template #right>
      <slot name="right" />
    </template>
    <template #navi>
      <slot name="navi" />
    </template>
  </wac-cms-header>
</template>

<script>
import WacCmsHeader from "./WacCmsHeader.vue";

export default {
  name: "WacCmsHeaderModule",
  components: {
    WacCmsHeader
  },
  props: {
    root: {
      type: String,
      require: true,
      default: "Home"
    },
    /** Property inner is used to pass tracking attributes to meta and main navigation */
    inner: {
      type: Object,
      default: undefined
    }
  }
};
</script>
