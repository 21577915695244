<template>
  <wac-breadcrumb class="wac-cms-breadcrumb" :items="items"></wac-breadcrumb>
</template>

<script>
import { WacBreadcrumb } from "wacker-pattern-library";

export default {
  name: "WacCmsBreadcrumb",
  components: {
    WacBreadcrumb
  },
  props: {
    /** Property items is used as breadcrumb items array */
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-breadcrumb {
  padding-top: 8px;
}
</style>
