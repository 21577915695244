<template>
  <div class="wac-cms-image-text-teaser">
    <wac-text
      v-if="headline"
      class="wac-cms-image-text-teaser__headline"
      :content="headlineContent"
    />
    <div class="wac-cms-image-text-teaser__items">
      <!-- @slot for WacCmsImageTextTeaserItem  -->
      <slot name="items" />
    </div>
  </div>
</template>

<script>
import { WacText } from "wacker-pattern-library";
/**
 * This component uses the following components from the wacker-pattern-library. See there for more details
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/story/layout-typography-text-wysiwyg--component-description">WacText</a>
 */
export default {
  name: "WacCmsImageTextTeaser",
  components: {
    WacText
  },
  props: {
    /** Property headline is used as headline for the image text teaser (optional) */
    headline: Array
  },
  computed: {
    headlineContent() {
      return [{ type: "h2", content: this.headline }];
    }
  }
};
</script>

<style lang="scss" scoped>
.wac-cms-image-text-teaser {
  $self: &;

  // Grid
  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(360px, 100%), 1fr));
    grid-gap: var(--spacing-300);
  }

  &::v-deep {
    #{ $self }__headline > * {
      margin-bottom: var(--spacing-150);
    }
  }
}
</style>
