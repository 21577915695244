export const formularCastingMold = {
  // Eingabe in cm3 - Ausgabe grams	=Dichte Material*(mold box MINUS model volume)
  "cm3:g": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume),

  // Eingabe in cm3 - Ausgabe kilograms	=Dichte Material*(mold box MINUS model volume)/1000
  "cm3:kg": (thickness, boxVolume, modelVolume) =>
    (thickness * (boxVolume - modelVolume)) / 1000,

  // Eingabe in m3 - Ausgabe grams	=Dichte Material*(mold box MINUS model volume)*1000000
  "m3:g": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume) * 1000000,

  // Eingabe in m3 - Ausgabe kilograms	=Dichte Material*(mold box MINUS model volume)*1000
  "m3:kg": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume) * 1000,

  // Eingabe in milliliters - Ausgabe grams	=Dichte Material*(mold box MINUS model volume)
  "ml:g": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume),

  // Eingabe in milliliters - Ausgabe kilograms	=Dichte Material*(mold box MINUS model volume)/1000
  "ml:kg": (thickness, boxVolume, modelVolume) =>
    (thickness * (boxVolume - modelVolume)) / 1000,

  // Eingabe in liters - Ausgabe grams	=Dichte Material*(mold box MINUS model volume)*1000000
  "l:g": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume) * 1000000,

  // Eingabe in liters - Ausgabe kilograms	=Dichte Material*(mold box MINUS model volume)*1000
  "l:kg": (thickness, boxVolume, modelVolume) =>
    thickness * (boxVolume - modelVolume) * 1000,
};

export const cm3Convert = {
  "m3:cm3": (x) => x * 1000000,
  "ml:cm3": (x) => x * 1,
  "cm3:cm3": (x) => x * 1,
  "l:cm3": (x) => x * 1000,
};

export const formularSkinMold = {
  // Eingabe in square mm(surface) - mm(layerThickness) - Ausgabe grams
  // Dichte Material*Schichtdicke*surface area/1000
  "mm2:mm:g": (material, layerThickness, surface) =>
    (material * layerThickness * surface) / 1000,
  // Eingabe in square mm- mm - Ausgabe kilograms
  // Dichte Material*Schichtdicke*surface area/1000000
  "mm2:mm:kg": (material, layerThickness, surface) =>
    (material * layerThickness * surface) / 1000000,
  // Eingabe in square cm - mm - Ausgabe grams
  // Dichte Material*Schichtdicke*surface area/10
  "cm2:mm:g": (material, layerThickness, surface) =>
    (material * layerThickness * surface) / 10,
  // Eingabe in square cm-  mm Ausgabe kilograms
  // Dichte Material*Schichtdicke*surface area/10000
  "cm2:mm:kg": (material, layerThickness, surface) =>
    (material * layerThickness * surface) / 10000,
  // Eingabe in square m - mm - Ausgabe grams
  // Dichte Material*Schichtdicke*surface area*1000
  "m2:mm:g": (material, layerThickness, surface) =>
    material * layerThickness * surface * 1000,
  // Eingabe in square m- mm - Ausgabe kilograms
  // Dichte Material*Schichtdicke*surface area
  "m2:mm:kg": (material, layerThickness, surface) =>
    material * layerThickness * surface,
};

export const isValidInput = (value) => value !== undefined && value !== "";

export const preselectFirst = (
  sourceSelection,
  targetSelection,
  targetValue,
  self
) => {
  const copy = JSON.parse(JSON.stringify(sourceSelection));
  const head = copy.shift();

  self[targetSelection] = [{ ...head, selected: "selected" }, ...copy];
  self[targetValue] = head.value;
};

export const numberFormat = (value, langCode) => {
  if (!value) {
    return value;
  } else if (langCode === "de") {
    return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 3 }).format(value);
  } else {
    return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 3 }).format(value);
  }
};
