<template>
  <section :id="elId" />
</template>
<script>
export default {
  name: "WacCmsInclude",
  props: {
    el: String
  },
  data() {
    return {
      includedNode: undefined,
      originalParent: undefined
    };
  },
  computed: {
    elId() {
      return `wac-cms-include-container--${this.el}`;
    }
  },
  mounted() {
    this.includedNode = document.getElementById(this.el);
    this.originalParent = this.includedNode.parentNode;
    document.getElementById(this.elId).appendChild(this.includedNode);
  },
  beforeDestroy() {
    this.originalParent.appendChild(this.includedNode);
  }
};
</script>
