<template>
  <div class="wac-cms-footer-column">
    <slot />
  </div>
</template>
<script>
export default {
  name: "WacCmsFooterColumn"
};
</script>
<style lang="scss" scoped>
.wac-cms-footer-column {
  padding-bottom: var(--spacing-300);
}
</style>
