<template>
  <wac-background class="wac-cms-text-media-module" :variant="variant">
    <wac-layout>
      <wac-spacing>
        <wac-text-media v-bind="$props" :level="2" :variant="null">
          <!-- @slot Description for link slot -->
          <slot slot="link" name="link" />
          <!-- @slot Description for button slot -->
          <slot slot="button" name="button" />
          <!-- @slot Describes the media slot <br>
          <code><pre>
          media: [
              {
              "type": "wac-cms-image",
              "props": {
                  "url": "assets/looking_through_microscope_480x320.jpg",
                  "width": 480,
                  "height": 320,
                  "title": "Hello World",
                  "alt": "Alternate Text"
              }
              }
          ]
          </pre></code>
          -->
          <slot slot="media" name="media" />
        </wac-text-media>
      </wac-spacing>
    </wac-layout>
  </wac-background>
</template>

<script>
import { WacBackground, WacLayout, WacTextMedia } from "wacker-pattern-library";
//TODO: Use WacSpacing from pattern-library
import WacSpacing from "../../internal/WacSpacing/WacSpacing.vue";
/**
 * This modul use the following components from the wacker-pattern-library. See there for more details<br>
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/docs/components-molecules-button--default">WacButton</a>,
 * <a href="https://pattern-library.wacker.com/refs/heads/master/?path=/docs/components-molecules-link--default">WacLink</a>
 */
export default {
  name: "WacCmsTextMediaModule",
  components: {
    WacBackground,
    WacLayout,
    WacTextMedia,
    WacSpacing
  },
  props: {
    headline: Array,
    content: Array,
    /**
     * Image or media subline as content array. Text is only displayed if a media component is included.
     */
    subline: Array,
    alignment: String,
    size: String,
    variant: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style lang="scss" scoped>
.wac-cms-text-media-module {
  // black plus icon in different background variants
  &.wac-background {
    &--primary::v-deep,
    &--dark::v-deep {
      .wac-icon-plus {
        color: var(--wac-forecolor-white-bg);
      }
    }

    // white cross icon and white modal text in  different background variants
    &--white::v-deep,
    &--secondary::v-deep,
    &--light::v-deep {
      .wac-icon-cross,
      .wac-cms-modal__headline,
      .wac-cms-modal__subline {
        color: var(--wac-forecolor-primary-bg);
      }
    }
  }

  ::v-deep {
    // Media subline
    .wac-text-media-slot .wac-text {
      @include fontSize(14px, 1.25);
      margin-top: 5px;

      @media only screen and (min-width: $bp-medium) {
        margin-top: var(--spacing-100);
      }
    }
    // Needed space for the controls of the video player
    .wac-cms-video {
      margin-bottom: var(--spacing-300);
    }

    // Link color
    .wac-text a {
      color: inherit;
    }
  }
}
</style>
<style lang="scss">
// Style for all modules including accordion etc.
.wac-text-media {
  // UL List
  .wac-text-content {
    ul {
      @include fontSize(18px, inherit);
    }
  }
}
</style>
