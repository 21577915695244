<template>
  <wac-cms-form
    v-if="active"
    v-bind="$props"
    class="wac-cms-form-include"
  ></wac-cms-form>
</template>

<script>
import { WacCmsForm } from "wacker-formframework";
import "../../../../node_modules/wacker-formframework/index.css";

export default {
  name: "WacCmsFormInclude",
  components: {
    WacCmsForm,
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    presets: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      active: true,
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.active = false;
        this.$nextTick(() => {
          this.active = true;
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wac-cms-form-include::v-deep {
  .wac-input input:focus,
  .wac-input input:focus-visible,
  .wac-input input:hover,
  .wac-textarea textarea:focus,
  .wac-textarea textarea:focus-visible,
  .wac-textarea textarea:hover {
    border-color: var(--wac-bgcolor-primary);
  }
}
</style>
